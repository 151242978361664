import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { UserDashboardContainer } from '../../containers'
import { PageNotFound } from '../../components'

export const INTRO_ROUTE = '/intro'
export const DASHBOARD_ROUTE = '/dashboard'
export const DASHBOARD_KEY_ROUTE = '/dashboard/:key'
export const BUILDER_ROUTE = '/builder/:id'
export const BUILDER_KEY_ROUTE = '/builder/:id/:key'
export const PREVIEW_ROUTE = '/preview/:id'
export const DashboardRoutes = () => (
  <Switch>
    <Route
      exact path={[DASHBOARD_ROUTE, DASHBOARD_KEY_ROUTE]}
      render={props => <UserDashboardContainer {...props} type={'dashboard'} />} />
    <Route
      exact path={[BUILDER_ROUTE, BUILDER_KEY_ROUTE]}
      render={props => <UserDashboardContainer {...props} type={'builder'} />} />
    <Route
      exact path={[PREVIEW_ROUTE]}
      render={props => <UserDashboardContainer {...props} type={'preview'} />} />
    <Route
      exact path={[INTRO_ROUTE]}
      render={props => <UserDashboardContainer {...props} type={'intro'} />} />
    <Route component={PageNotFound} path="*" />
  </Switch>
)
