import { isFunction } from 'lodash'
import { fetchVisitors, fetchSubscribers, fetchOrdersReport, fetchResumesReport, fetchUsers, searchResumes,
  fetchSubscriptionReport, fetchDetails, getAdminsGeneralReportData, sendPromoEmails } from '../services'

export default class AdminModel {
  async fetchVisitors(cb = () => { }, startDate, endDate) {
    const response = await fetchVisitors(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchSubscribers(cb = () => { }, startDate, endDate) {
    const response = await fetchSubscribers(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getAdminsGeneralReportData(cb = () => { }, startDate, endDate, hours) {
    const response = await getAdminsGeneralReportData(startDate, endDate, hours)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchOrdersReport(cb = () => { }, startDate, endDate) {
    const response = await fetchOrdersReport(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchResumesReport(cb = () => { }, startDate, endDate) {
    const response = await fetchResumesReport(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchSubscriptionReport(cb = () => { }, startDate, endDate) {
    const response = await fetchSubscriptionReport(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchUsers(cb = () => { }, startDate, endDate) {
    const response = await fetchUsers(startDate, endDate)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchDetails(refAPI, refKeyVal, cb = () => { }) {
    const response = await fetchDetails(refAPI, refKeyVal)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async searchResumes(userName, resumeName, cb) {
    const response = await searchResumes(userName, resumeName)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async sendPromoEmails(payload, cb) {
    const response = await sendPromoEmails(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }
}
