import { isFunction } from 'lodash'
import { getUniversities, getCompanies, getCities, getStates, getAchievements, fetchHobbies,
  fetchQualifications, fetchTrainings, fetchEmploymentResponsibilities, fetchObjectives, fetchSummaries,
  fetchSkills, fetchLanguages, fetchProjectResponsibilities, fetchRoles, fetchDomains, fetchCertifications,
  fetchInstitutions } from '../services'

export default class UtilitiesModel {
  async getUniversities(searchkey, cb = () => { }) {
    const response = await getUniversities(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getCompanies(searchkey, cb = () => { }) {
    const response = await getCompanies(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getCities(searchkey, cb = () => { }) {
    const response = await getCities(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getStates(searchkey, cb = () => { }) {
    const response = await getStates(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getAchievements(searchkey, cb = () => { }) {
    const response = await getAchievements(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchHobbies(searchkey, type, cb = () => { }) {
    const response = await fetchHobbies(searchkey, type)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchSkills(searchkey, cb = () => { }) {
    const response = await fetchSkills(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchRoles(searchkey, cb = () => { }) {
    const response = await fetchRoles(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchDomains(searchkey, cb = () => { }) {
    const response = await fetchDomains(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchQualifications(searchkey, cb = () => { }) {
    const response = await fetchQualifications(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchEmploymentResponsibilities(key, cb = () => { }) {
    const response = await fetchEmploymentResponsibilities(key)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchProjectResponsibilities(key, cb = () => { }) {
    const response = await fetchProjectResponsibilities(key)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchTrainings(searchkey, cb = () => { }) {
    const response = await fetchTrainings(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchCertifications(searchkey, cb = () => { }) {
    const response = await fetchCertifications(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchInstitutions(searchkey, cb = () => { }) {
    const response = await fetchInstitutions(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchObjectives(criteria, key, cb = () => { }) {
    const response = await fetchObjectives(criteria, key)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchSummaries(criteria, key, cb = () => { }) {
    const response = await fetchSummaries(criteria, key)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchLanguages(searchkey, cb = () => { }) {
    const response = await fetchLanguages(searchkey)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }
}
