/* eslint-disable max-len */
import { get, set, findKey, includes, map, forEach } from 'lodash'

export const EMPTY_STRING = ''
export const DATE_FORMAT = 'MMM yyyy'

export const SOCIAL_NETWORK = {
  LINKEDIN: { prefix: 'https://www.linked.in/in/' },
}

export const MONTHS = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const DOMAIN = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Administrative', label: 'Administrative' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Banking', label: 'Banking' },
  { value: 'BFSI', label: 'BFSI' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Communications', label: 'Communications' },
  { value: 'Computer', label: 'Computer' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Customs and Duties', label: 'Customs and Duties' },
  { value: 'Digital Marketing', label: 'Digital Marketing' },
  { value: 'E-Commerce', label: 'E-Commerce' },
  { value: 'Education', label: 'Education' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Food', label: 'Food' },
  { value: 'Graphics and Designing', label: 'Graphics and Designing' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Human Resource', label: 'Human Resource' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Logistics & Supply chain', label: 'Logistics & Supply chain' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Mining', label: 'Mining' },
  { value: 'Music & Cinematography', label: 'Music & Cinematography' },
  { value: 'News and Media', label: 'News and Media' },
  { value: 'Oil and Gas', label: 'Oil and Gas' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Public Sector', label: 'Public Sector' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Sales & Marketing', label: 'Sales & Marketing' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Textile', label: 'Textile' },
  { value: 'Travel', label: 'Travel' },
]

export const MESSAGES = {
  OTP_VALIDATED_SUCCESS: 'Your account is activated. Please login.',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
}

export const SEVERITY = {
  ERROR: 'error',
  WARN: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
}

export const SUBSCRIPTIONPLANS = {
  FREE: { id: '1', display: 'Free', name: 'Free Plan ', sub_note: 'One time offer', value: '0', features: ['Free access for 7 days', 'Access free template only', 'Unlimited edit options', 'Download one Resume', 'Contains Brand watermark'] },
  MONTHLY: { id: '2', display: 'Monthly', name: 'One Month', sub_note: 'One month access', value: '899', features: ['Unlimited downloads', 'Access popular Resume templates', 'Full access to all features', 'Realtime edits   ', 'Industry-specific recommendations', 'Customize how you like', 'ATS Friendly features', 'Pay monthly, use unlimited features'] },
  PAYASUGO: { id: '3', display: 'Pay as you go', name: 'Pay As You Go', sub_note: 'one resume', value: '299', features: ['Download limit one Resume', 'Access popular Resume templates', 'Full access to all features', 'Realtime edits   ', 'Industry-specific recommendations', 'Customize how you like', 'Unlimited edit options', 'ATS Friendly features'] },
}

export const THEMES = {
  THEME1: 'theme_1',
  THEME2: 'theme_2',
  THEME3: 'theme_3',
  THEME4: 'theme_4',
  THEME5: 'theme_5',
  THEME11: 'theme_11',
  THEME12: 'theme_12',
  THEME13: 'theme_13',
  THEME14: 'theme_14',
  THEME15: 'theme_15',
  THEME6: 'theme_6',
  THEME7: 'theme_7',
  THEME8: 'theme_8',
}

export const FONTS = {
  Poppins: 'Poppins',
  OpenSans: 'OpenSans',
  Lato: 'Lato',
  Montserrat: 'Montserrat',
  Inter: 'Inter',
  Comfortaa: 'Comfortaa',
  Exo2: 'Exo2',
  Oxygen: 'Oxygen',
  Spartan: 'Spartan',
  ElMessiri: 'ElMessiri',
  RobotoSlab: 'RobotoSlab',
  ZillaSlab: 'ZillaSlab',
  PTSerif: 'PTSerif',
  Merienda: 'Merienda',
  PoiretOne: 'PoiretOne',
}

export const DEFAULT_POSITIONS = {
  ORION: [
    [{ CONTACTS: { static: true } }],
    [{ OBJECTIVE: { static: true } }],
    [{ EDUCATION: { static: false } }, { SUMMARY: { static: false } }],
    [{ SKILLS: { static: false } }, { EMPLOYMENT: { static: false } }],
    [{ ACHIEVEMENTS: { static: false } }, { PROJECT: { static: false } }],
    [{ TRAININGS: { static: false } }, {}],
    [{ CERTIFICATION: { static: false } }, {}],
    [{ LANGUAGES: { static: false } }, { }],
    [{ HOBBIES: { static: false } }, {}],
  ],
  IRIS: [
    [{ CONTACTS: { static: false } }, { OBJECTIVE: { static: false } }],
    [{ SKILLS: { static: false } }, { SUMMARY: { static: false } }],
    [{ EDUCATION: { static: false } }, { EMPLOYMENT: { static: false } }],
    [{ TRAININGS: { static: false } }, { ACHIEVEMENTS: { static: false } }],
    [{ CERTIFICATION: { static: false } }, { PROJECT: { static: false } }],
    [{ HOBBIES: { static: false } }, { }],
    [{ LANGUAGES: { static: false } }, { }],
  ],
  CETUS: [
    [{ CONTACTS: { static: true } }],
    [{ EDUCATION: { static: false } }, { OBJECTIVE: { static: false } }],
    [{ SKILLS: { static: false } }, { SUMMARY: { static: false } }],
    [{ HOBBIES: { static: false } }, { ACHIEVEMENTS: { static: false } }],
    [{ TRAININGS: { static: false } }, { PROJECT: { static: false } }],
    [{ CERTIFICATION: { static: false } }, { EMPLOYMENT: { static: false } }],
    [{ LANGUAGES: { static: false } }, {}],
  ],
  MARS: [
    [{ CONTACTS: { static: true } }, { OBJECTIVE: { static: false } }],
    [{ SKILLS: { static: false } }, { SUMMARY: { static: false } }],
    [{ EDUCATION: { static: false } }, { ACHIEVEMENTS: { static: false } }],
    [{ EMPLOYMENT: { static: false } }, { PROJECT: { static: false } }],
    [{ CERTIFICATION: { static: false } }, { TRAININGS: { static: false } }],
    [{ LANGUAGES: { static: false } }, { }],
    [{ HOBBIES: { static: false } }, { }],
  ],
  LYRA: [
    [{ CONTACTS: { static: true } }],
    [{ OBJECTIVE: { static: true } }],
    [{ SKILLS: { static: false } }],
    [{ SUMMARY: { static: false } }],
    [{ EDUCATION: { static: false } }],
    [{ EMPLOYMENT: { static: false } }],
    [{ PROJECT: { static: false } }],
    [{ ACHIEVEMENTS: { static: false } }],
    [{ HOBBIES: { static: false } }],
    [{ CERTIFICATION: { static: false } }],
    [{ TRAININGS: { static: false } }],
    [{ LANGUAGES: { static: false } }],
  ],
  VENUS: [
    [{}, { CONTACTS: { static: true } }],
    [{ OBJECTIVE: { static: false } }],
    [{ SKILLS: { static: false } }],
    [{ SUMMARY: { static: false } }],
    [{ EDUCATION: { static: false } }],
    [{ EMPLOYMENT: { static: false } }],
    [{ PROJECT: { static: false } }],
    [{ ACHIEVEMENTS: { static: false } }],
    [{ HOBBIES: { static: false } }],
    [{ CERTIFICATION: { static: false } }],
    [{ TRAININGS: { static: false } }],
    [{ LANGUAGES: { static: false } }],
  ],
  PLUTO: [
    [{}, { CONTACTS: { static: true } }],
    [{ OBJECTIVE: { static: false } }, { SKILLS: { static: false } }],
    [{ SUMMARY: { static: false } }, { LANGUAGES: { static: false } }],
    [{ EDUCATION: { static: false } }, { CERTIFICATION: { static: false } }],
    [{ EMPLOYMENT: { static: false } }, { HOBBIES: { static: false } }],
    [{ PROJECT: { static: false } }, { TRAININGS: { static: false } }],
    [{ ACHIEVEMENTS: { static: false } }, { }],
  ],
  NUNKI: [
    [[], { OBJECTIVE: { static: true } }],
    [{ CONTACTS: { static: true } }],
    [{ SKILLS: { static: false } }, { EDUCATION: { static: false } }],
    [{ CERTIFICATION: { static: false } }, { SUMMARY: { static: false } }],
    [{ TRAININGS: { static: false } }, { EMPLOYMENT: { static: false } }],
    [{ LANGUAGES: { static: false } }, { PROJECT: { static: false } }],
    [{ HOBBIES: { static: false } }, { ACHIEVEMENTS: { static: false } }],
  ],
}

export const preparePosition = (posArr) => {
  const layout = {}
  const height = 2
  const totalCols = 2
  let xpos = 0
  let ypos = 0
  forEach(posArr, posRow => {
    const rowCols = posRow.length
    const width = totalCols / rowCols
    forEach(posRow, col => {
      map(col, (value, key) => {
        const gridLayout = { xpos, ypos, height, width, static: get(value, 'static', false) }
        set(layout, key, gridLayout)
      })
      xpos++
    })
    xpos = 0
    ypos += height
  })
  return layout
}

export const TEMPLATE = {
  ORION: { id: 'ORION',
    image: '/assets/images/orion.jpg',
    templateId: '1001',
    templateName: 'Orion',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.ORION),
    defaultTheme: THEMES.THEME1,
    defaultFont: FONTS.Poppins,
    description: 'Best option in town is me, a popular choice of many users.' },
  VENUS: { id: 'VENUS',
    image: '/assets/images/venus.jpg',
    alt: 'Venus Sample Template',
    templateId: '1005',
    templateName: 'Venus (Free)',
    validSubscriptions: [SUBSCRIPTIONPLANS.FREE.id, SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.VENUS),
    defaultTheme: THEMES.THEME12,
    defaultFont: FONTS.Inter,
    description: 'Standard resume formats generally used by many entry level professionals' },
  CETUS: { id: 'CETUS',
    image: '/assets/images/cetus.jpg',
    templateId: '1004',
    templateName: 'Cetus',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.CETUS),
    defaultTheme: THEMES.THEME15,
    defaultFont: FONTS.Spartan,
    description: 'Simplicity meets elegance. Exclusively for you.' },
  MARS: { id: 'MARS',
    image: '/assets/images/mars.jpg',
    templateId: '1007',
    templateName: 'Mars',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.MARS),
    defaultTheme: THEMES.THEME11,
    defaultFont: FONTS.Montserrat,
    description: 'Create a blasting presence of yourself this time' },
  LYRA: { id: 'LYRA',
    image: '/assets/images/lyra.jpg',
    alt: 'Lyra Sample Template',
    templateId: '1003',
    templateName: 'Lyra',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.LYRA),
    defaultTheme: THEMES.THEME6,
    defaultFont: FONTS.RobotoSlab,
    description: 'A classic resume format suites almost every profile' },
  IRIS: { id: 'IRIS',
    image: '/assets/images/iris.jpg',
    templateId: '1002',
    templateName: 'Iris',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.IRIS),
    defaultTheme: THEMES.THEME3,
    defaultFont: FONTS.Oxygen,
    description: 'Trendy with a brilliant presentation that everyone is impressed.' },
  PLUTO: { id: 'PLUTO',
    image: '/assets/images/pluto.jpg',
    alt: 'Pluto Sample Template',
    templateId: '1006',
    templateName: 'Pluto',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.PLUTO),
    defaultTheme: THEMES.THEME2,
    defaultFont: FONTS.Comfortaa,
    description: 'Modern and simplate resume presentation to well organize' },
  NUNKI: { id: 'NUNKI',
    image: '/assets/images/nunki.jpg',
    alt: 'Nunki Sample Template',
    templateId: '1008',
    templateName: 'Nunki',
    validSubscriptions: [SUBSCRIPTIONPLANS.MONTHLY.id, SUBSCRIPTIONPLANS.PAYASUGO.id],
    positions: preparePosition(DEFAULT_POSITIONS.NUNKI),
    defaultTheme: THEMES.THEME14,
    defaultFont: FONTS.OpenSans,
    description: 'Bring outllok to your personal profile with strong appearance' },
}

export const fetchTemplateNameFromId = (templateId) => {
  const templateKey = findKey(TEMPLATE, template => get(template, 'templateId') === `${templateId}`)
  return get(TEMPLATE[templateKey], 'templateName')
}


export const THEMES_SECONDARY = {
  THEME_SECONDARY1: 'theme_secondary_1',
  THEME_SECONDARY2: 'theme_secondary_2',
  THEME_SECONDARY3: 'theme_secondary_3',
  THEME_SECONDARY4: 'theme_secondary_4',
  THEME_SECONDARY5: 'theme_secondary_5',
}


export const INFO = {
  CONTACT: 'CONTACT',
}

export const SECTIONS = {
  OBJECTIVE: 'OBJECTIVE',
  SUMMARY: 'SUMMARY',
  EXPERIENCE: 'EXPERIENCE',
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  SKILLS: 'SKILLS',
  PROJECT: 'PROJECT',
  EDUCATION: 'EDUCATION',
  TRAININGS: 'TRAININGS',
  CERTIFICATION: 'CERTIFICATION',
  HOBBIES: 'HOBBIES',
  LANGUAGES: 'LANGUAGES',
  EMPLOYMENT: 'EMPLOYMENT',
  CUSTOM: 'CUSTOM',
  CONTACT: 'CONTACTS',
}

export const PERSONALINFO = {
  MAIN: 'PERSONAL',
  CONTACTS: 'CONTACTS',
  CONTACT_CHILD: 'CONTACT_CHILD',
  IMAGE: 'IMAGE',
  NAME: 'NAME',
}

export const SKILLLAYOUT = {
  TAG: 'tag',
  RATING: 'rating',
}

export const HOBBYLAYOUT = {
  ICON: { name: 'icon', show: false },
  TEXT: { name: 'text' },
}

export const SHOW_OPTIONS = {
  TITLE: 'title',
  TEXT: 'text',
  ALL: 'all',
  PARENT: 'parent',
  CHILD: 'child',
}

export const DESIGN_OPTIONS = {
  TEMPLATE: 'Templates',
  THEME: 'Themes',
  FONTS: 'Fonts',
  SECTION: 'Section Styles',
}

export const PREVIEWPANELOPTIONS = {
  BOLD_ITALIC_UNDERLINE: 'BOLD_ITALIC_UNDERLINE',
  ALIGNMENT: 'ALIGNMENT',
  FONT_SIZE: 'FONT_SIZE',
  FONTFAMILY: 'FONTFAMILY',
  BULLETSTYLE: 'BULLETSTYLE',
  TAGS: 'TAGS',
  RATING: 'RATING',
  BOXSTYLE: 'BOXSTYLE',
  RATING_ADV: 'RATING_ADV,',
  CALENDAR: 'CALENDAR',
  TITLE_COLOR: 'TITLE_COLOR',
  PROFILE_IMAGE_OPTIONS: 'PROFILE_IMAGE_OPTIONS',
  PROFILE_IMAGE_BG: 'PROFILE_IMAGE_BG',
  CONTACT_IMAGE: 'CONTACT_IMAGE',
  CONTACT_IMAGE_SIZE: 'CONTACT_IMAGE_SIZE',
  CONTACT_OPTIONS: 'CONTACT_OPTIONS',
  CONTACT_SECTION: 'CONTACT_SECTION',
  HOBBY_IMG: 'HOBBY_IMG',
  BORDER_DECORATE: 'BORDER_DECORATE',
  EXTRA_FONTS_DEFAULT: 'EXTRA_FONTS_DEFAULT',
  EXTRA_FONTS_ADON: 'EXTRA_FONTS_ADON',
}

export const SEO = {
  DESCRIPTION: 'Resumaker is most advanced resume builder using which anyone can create resume instantly, customize template, themes, colors and well formatted pdf that is ready to sent to employer',
  EXPERT_DESCRIPTION: 'Get Resumaker expert resume services. Our professional team is ready to create a magical resume that will help you to get your dream job. Click to know more! ',
}

export const FOOTER = {
  COPY_RIGH: '© 2018 - 2022 Resumaker.',
  RIGHTS: 'All rights reserved.',
}

export const getTemplate = (key) => {
  let template = TEMPLATE[key]
  if (template) {
    return template
  }
  map(TEMPLATE, (value, k) => {
    if (get(value, 'id') === key){
      template = value
    }
  })

  return template
}

export const isTemplateValid = (templateKey, subscriptionPlan) => {
  const template = getTemplate(templateKey)
  return includes(get(template, 'validSubscriptions'), `${subscriptionPlan}`)
}
