import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { withRouter } from 'react-router-dom'
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import st from './style.scss'
import { Icon, IconType } from '..'

class MainHeader extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    sideBarMini: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    loginWithRedirect: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    toggleSideBar: PropTypes.func,
  }

  static defaultProps = {
    sideBarMini: false,
    toggleSideBar: _ => _,
  }

  constructor(props){
    super(props)
    this.state = {
      top: window.pageYOffset === 0,
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      top: window.pageYOffset === 0,
    })
  }

  renderLogo = () => {
    const { top } = this.state
    const { isLoggedIn, loginWithRedirect } = this.props
    const { SAMPLE_1, SAMPLE_2, SAMPLE_3 } = IconType.NAV
    const { NAV_TEMPLATE_1, NAV_TEMPLATE_2, NAV_TEMPLATE_3, NAV_TEMPLATE_4, NAV_TEMPLATE_5 } = IconType.NAV
    const { NAV_TARGET_1, NAV_TARGET_2, NAV_TARGET_3 } = IconType.NAV
    const { NAV_HR_1 } = IconType.NAV
    const { NAV_IT_1 } = IconType.NAV
    const { NAV_MARKET_1, NAV_MARKET_2 } = IconType.NAV
    const { NAV_LATEST_1, NAV_LATEST_2 } = IconType.NAV
    const { NAV_SKILL_1, NAV_SKILL_2, NAV_SKILL_3 } = IconType.NAV
    const { NAV_OPP_1, NAV_OPP_2, NAV_OPP_3, NAV_OPP_4, NAV_OPP_5, NAV_OPP_6, NAV_OPP_7, NAV_OPP_8, NAV_OPP_9 }
    = IconType.NAV
    const { NAV_FRESHER_1 } = IconType.NAV
    const { NAV_PROF_1, NAV_PROF_2, NAV_PROF_3, NAV_PROF_4 } = IconType.NAV
    const { NAV_EXE_1 } = IconType.NAV
    const { NAV_CAREER_1 } = IconType.NAV
    const { HOME_SOLID_1, SIGN_OUT_ALT_SOLID_1 } = IconType.USER_DASHBOARD
    const { TEMPLATE_1, TEMPLATE_2, TEMPLATE_3 } = IconType.PREVIEW
    return (
      <Container className={cs(st.cc_fluid_pp, { [st.nav_noshadow]: top }, { [st.nav_shadow]: !top })} fluid>
        {/* <div className={cs({ [st.nav_noshadow]: top }, { [st.nav_shadow]: !top })}> */}
        <Navbar className={cs(st.nav_color, st.nav_basic, 'container')} collapseOnSelect expand="lg">
          <Navbar.Brand className={cs(st.nav_brand_top_logo)}>
            <div onClick={() => this.props.gotoURL('/')}>
              <img
                alt="Resumaker Logo"
                className="d-inline-block align-top navbar-nav-svg"
                src="/assets/images/logo.svg"
              /></div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className={cs(st.nav_responsive_mr)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" />
            <Nav>
              <NavDropdown
                className={cs(st.nav_list_padding, st.nav_title)}
                id="collasible-nav-dropdown" title="Resume">
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/sample-resume')}>
                  <Icon
                    height={'20'}
                    names={[SAMPLE_1, SAMPLE_2, SAMPLE_3]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-sample.svg" /> */}
                  Samples <span className={cs(st.nav_span_content)}>
                    Find perfect resume samples. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown, st.nav_career_change)}
                  onClick={() => this.props.gotoURL('/resume-templates')}>
                  <Icon
                    height={'20'}
                    names={[NAV_TEMPLATE_1, NAV_TEMPLATE_2, NAV_TEMPLATE_3, NAV_TEMPLATE_4, NAV_TEMPLATE_5]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-template.svg" /> */}
                  Templates <span className={cs(st.nav_span_content)}>
                    Find the perfect resume template. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown, st.nav_last_child)}
                  onClick={() => loginWithRedirect({ redirectTo: '/dashboard/create' })}>
                  Create Resume Now
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown className={cs(st.nav_title)} id="collasible-nav-dropdown" title="Interview">
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/interview')}>
                  <Icon
                    height={'20'}
                    names={[NAV_TARGET_1, NAV_TARGET_2, NAV_TARGET_3]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-target.svg" /> */}
                  Must Know <span className={cs(st.nav_span_content)}>
                    Every interview follow basic rules. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/interview')}>
                  <Icon
                    height={'20'}
                    names={[NAV_HR_1]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-hr.svg" /> */}
                  Working with HR <span className={cs(st.nav_span_content)}>
                    Facing HR is very different, you must win the battle on negotiation. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/interview')}>
                  <Icon
                    height={'20'}
                    names={[NAV_IT_1]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-it.svg" /> */}
                  IT Jobs <span className={cs(st.nav_span_content)}>
                    Find top IT questions that push you to next level. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown, st.nav_career_change)}
                  onClick={() => this.props.gotoURL('/interview')}>
                  <Icon
                    height={'20'}
                    names={[NAV_MARKET_1, NAV_MARKET_2]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-market.svg" /> */}
                  Marketing Jobs <span className={cs(st.nav_span_content)}>
                    Most challenging profession, you must know these tips. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown,
                    st.nav_last_child)}
                  onClick={() => this.props.gotoURL('/interview')}>
                  More
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                className={cs(st.nav_title, st.blogs_level_nav)}
                id="collasible-nav-dropdown" title="Blogs">
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/blog')}>
                  <Icon
                    height={'20'}
                    names={[NAV_LATEST_1, NAV_LATEST_2]}
                    viewHeight={'32'}
                    viewWidth={'32'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-latest.svg" /> */}
                  Latest <span className={cs(st.nav_span_content)}>
                    The latest trends, opportunies and many more. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/blog', { category: 'skills' })}>
                  <Icon
                    height={'20'}
                    names={[NAV_SKILL_1, NAV_SKILL_2, NAV_SKILL_3]}
                    viewHeight={'30'}
                    viewWidth={'30'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-skill.svg" /> */}
                  Skills <span className={cs(st.nav_span_content)}>
                    Improve your skills, we have articulated for you nicely. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/blog', { category: 'opportunities' })}>
                  <Icon
                    height={'20'}
                    names={[NAV_OPP_1, NAV_OPP_2, NAV_OPP_3, NAV_OPP_4, NAV_OPP_5,
                      NAV_OPP_6, NAV_OPP_7, NAV_OPP_8, NAV_OPP_9]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-opp.svg" /> */}
                  Opportunities <span className={cs(st.nav_span_content)}>
                    We do the reseach for you, here is the top opportunities list. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown, st.nav_last_child)}
                  onClick={() => this.props.gotoURL('/blog')}>
                  More
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                className={cs(st.nav_title, st.hire_expert_nav)}
                id="collasible-nav-dropdown" title="Hire Expert">
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/hire-expert')}>
                  <Icon
                    height={'20'}
                    names={[NAV_FRESHER_1]}
                    viewHeight={'50'}
                    viewWidth={'50'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-fresher.svg" /> */}
                  Entry Level <span className={cs(st.nav_span_content)}>
                    Done with your academics?&nbsp;&nbsp;
                    we write your resume perfectly suites your first job. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/hire-expert', { activeTab: 'professional' })}>
                  <Icon
                    height={'20'}
                    names={[NAV_PROF_1, NAV_PROF_2, NAV_PROF_3, NAV_PROF_4]}
                    viewHeight={'36'}
                    viewWidth={'36'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-prof.svg" /> */}
                  Professional <span className={cs(st.nav_span_content)}>
                    Switching employment is challenging, our experts write
                    influencing resume for you. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/hire-expert', { activeTab: 'executive' })}>
                  <Icon
                    height={'20'}
                    names={[NAV_EXE_1]}
                    viewHeight={'24'}
                    viewWidth={'24'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-exe.svg" /> */}
                  Executive <span className={cs(st.nav_span_content)}>
                    Years of experiecne and great achievements ?
                    InfoGraphics resume is best fit for you. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown, st.nav_career_change)}
                  onClick={() => this.props.gotoURL('/hire-expert', { activeTab: 'career' })}>
                  <Icon
                    height={'20'}
                    names={[NAV_CAREER_1]}
                    viewHeight={'1024'}
                    viewWidth={'1024'} width={'20'} />
                  {/* <img alt="template" src="/assets/images/nav-career.svg" /> */}
                  Career Change <span className={cs(st.nav_span_content)}>
                    Our writers have written many game changing resumes, ask for yours. </span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_dropdown_item, st.nav_navbar_dropdown, st.nav_last_child)}
                  onClick={() => this.props.gotoURL('/hire-expert')}>
                  Start Now
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                className={cs(st.nav_title, st.hire_expert_nav)}
                id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/pricing')}
                title="Pricing" />
            </Nav>
            <Nav>
              {!isLoggedIn && (
              <NavDropdown
                className={cs(st.nav_title, st.hire_expert_nav)}
                id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/login')}
                title="Login" />
              )}
              {isLoggedIn && (
              <NavDropdown
                className={cs(st.nav_title, st.hire_expert_nav)}
                id="collasible-nav-dropdown" title={(
                  <img
                    alt={'avatar'}
                    className={cs(st.login_icon)} src={'/assets/images/login.png'} />
                )}>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/dashboard')}>
                  <Icon
                    names={[HOME_SOLID_1]}
                    viewHeight={'512'}
                    viewWidth={'576'} />
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.gotoURL('/dashboard/myresume')}>
                  <Icon
                    names={[TEMPLATE_1, TEMPLATE_2, TEMPLATE_3]}
                    viewHeight={'24'}
                    viewWidth={'24'} />
                  Resumes
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs(st.nav_navbar_dropdown)}
                  onClick={() => this.props.logout()}>
                  <Icon
                    names={[SIGN_OUT_ALT_SOLID_1]}
                    viewHeight={'512'}
                    viewWidth={'512'} />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* </div> */}
      </Container>
    )
  }
  render = () => (
    <Fragment>
      {this.renderLogo()}
    </Fragment>
  )
}

MainHeader.propTypes = {
  alignLeft: PropTypes.bool.isRequired,
  applicationModel: PropTypes.object.isRequired,
  history: PropTypes.object,
  node: PropTypes.node,
  onSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  showLogout: PropTypes.bool,
  tabBody: PropTypes.array.isRequired,
}

MainHeader.defaultProps = {
  node: null,
  history: {},
  selectedTab: 0,
  onSelect: () => {},
  showLogout: true,
}
export default withRouter(MainHeader)
