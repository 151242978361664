import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import { Container, InputGroup, FormControl, Button } from 'react-bootstrap'
import { FOOTER } from '../../../constants'
import { Icon, IconType } from '..'
import styles from './style.scss'

class MainFooter extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      subscriptionEmail: '',
    }
  }
  subscribe = () => {
    const { subscribe } = this.props
    const { subscriptionEmail } = this.state
    const cb = (message) => {
      this.setState({
        subscriptionEmail: message,
      })
    }
    subscribe(subscriptionEmail, cb)
  }

  render() {
    const { FACEBOOK, LINKEDIN, TWITTER, QUORA, YOUTUBE } = IconType.SOCIAL
    const { subscriptionEmail } = this.state
    return (
      <div>
        <div className={classnames(styles.section)}>
          <div className={classnames(styles.sub_footer)}>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3">
                  <div
                    className={classnames(styles.logo)}
                    onClick={() => this.props.gotoURL('/')}>
                    <img
                      alt={'logo'}
                      className={classnames(
                        styles.footer_logo,
                        styles.logo_responisve,
                      )}
                      src={'/assets/images/logo.svg'} />
                  </div>
                  <div className={classnames(styles.footer_icons_social_list)}>
                    <ul>
                      <li className={classnames(styles.footer_facebook)}>
                        {' '}
                        <a
                          href="https://www.facebook.com/resumaker"
                          target="_blank">
                          <Icon
                            color={'#f5f5f5'}
                            names={[FACEBOOK]}
                            styles={'icon10 darkGrayAlt'}
                          />{' '}
                        </a>{' '}
                      </li>
                      <li className={classnames(styles.footer_linkdien)}>
                        {' '}
                        <a
                          href="https://www.linkedin.com/company/resumaker"
                          target="_blank">
                          <Icon
                            color={'#f5f5f5'}
                            names={[LINKEDIN]}
                            styles={'icon10 darkGrayAlt'}
                          />{' '}
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a
                          href="https://twitter.com/resumakercoin"
                          target="_blank">
                          <Icon
                            color={'#f5f5f5'}
                            names={[TWITTER]}
                            styles={'icon10 darkGrayAlt'}
                          />{' '}
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a
                          href="https://www.quora.com/resumaker"
                          target="_blank">
                          <Icon
                            color={'#f5f5f5'}
                            names={[QUORA]}
                            styles={'icon10 darkGrayAlt'} />{' '}
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a
                          href="https://www.youtube.com/resumaker"
                          target="_blank">
                          <Icon
                            color={'#f5f5f5'}
                            names={[YOUTUBE]}
                            styles={'icon10 darkGrayAlt'} />{' '}
                        </a>{' '}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3">
                  <h4 className={classnames(styles.h4_title, styles.footer_h3)}>
                    {' '}
                    Useful Links{' '}
                  </h4>
                  <ul className={classnames(styles.footer_menu_links)}>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/about')}>
                        About Us{' '}
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/resume-templates')}>
                        {' '}
                        Build My Resume
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/interview')}>
                        Interview Questions{' '}
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/sample-resume')}>
                        Sample Resumes{' '}
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/resume-templates')}>
                        Templates{' '}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-2">
                  <h4 className={classnames(styles.h4_title, styles.footer_h3)}>
                    {' '}
                    Support{' '}
                  </h4>
                  <ul className={classnames(styles.footer_menu_links)}>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/pricing')}>
                        {' '}
                        Pricing
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/blog')}>
                        Blogs{' '}
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/hire-expert')}>
                        {' '}
                        Expert Resume
                      </div>
                    </li>
                    <li>
                      {' '}
                      <div onClick={() => this.props.gotoURL('/contact')}>
                        {' '}
                        Contact Us{' '}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-4">
                  <h4 className={classnames(styles.h4_title, styles.footer_h3)}>
                    {' '}
                    Get Latest News &amp; Updates Here{' '}
                  </h4>
                  <InputGroup className="mb-3">
                    <FormControl
                      className={classnames(styles.subscribe_input)}
                      onChange={e => this.setState({ subscriptionEmail: e.target.value })}
                      placeholder="Please Enter Your Email Here"
                      value={subscriptionEmail} />
                    <InputGroup.Append>
                      <Button
                        className={classnames(styles.subscribe_btn)}
                        onClick={this.subscribe}
                        variant="outline-secondary">
                        Subscribe
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>

                  <div className="verify">
                    <img
                      alt="resumaker-ssl"
                      className={classnames(styles.footer_verify_logo)}
                      src="/assets/images/verify.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <Container>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                <p className={classnames(styles.footer_content)}>
                  {FOOTER.COPY_RIGH} {FOOTER.RIGHTS}
                </p>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                <div
                  className={classnames(
                    styles.footer_content,
                    styles.footer_content_anchor,
                  )}
                >
                  <div
                    className={classnames(styles.footer_content_anchor_link)}
                    onClick={() => this.props.gotoURL('/faqs')}
                  >
                    FAQs.
                  </div>
                  <div
                    className={classnames(styles.footer_content_anchor_link)}
                    onClick={() => this.props.gotoURL('/terms-conditions')}
                  >
                    Terms &amp; Conditions.
                  </div>
                  <div
                    className={classnames(styles.footer_content_anchor_link)}
                    onClick={() => this.props.gotoURL('/privacy-policy')}
                  >
                    Privacy Policy.
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    )
  }
}
export default withRouter(MainFooter)
