import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './style.scss'

export default class TextareaComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
    style: {},
  }

  getLabel = () => {
    const { formField, field } = this.props
    if (formField) {
      return formField.get('label')
    }
    return field.label
  }

  render() {
    const { field, input, showLabel, formField, style } = this.props
    const { editable, disabled } = field
    return (
      <div className={classnames(styles['textarea-container'], 'flex-auto')}>
        {showLabel && (
        <div className={styles.textAreaContainer}>
          <div className={classnames(styles.textAreaLabel, 'label')}>{this.getLabel()}</div>
        </div>
        )}
        {editable === false ?
          <div className={styles['non-editable-value']}>{input.value}</div> : (
            <div>
              {!formField ? (
                <textarea
                  placeholder={field.placeholder}
                  {...input}
                  className={classnames(styles.textarea, style)}
                  disabled={disabled}
                  rows={field.rows} />
              ) : (
                <textarea
                {...formField.bind()} // eslint-disable-line
                  className={classnames(styles.textarea, style)}
                  disabled={disabled}
                  placeholder={field.placeholder}
                  rows={field.rows} />
              )}
            </div>
          )}
      </div>
    )
  }
}
