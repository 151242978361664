import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NumberComponent from './Number'
import StringComponent from './String'
import CheckboxComponent from './Checkbox'
import PasswordComponent from './Password'
import TextAreaComponent from './Textarea'
import RadioButtonComponent from './RadioButton'
import CustomComponent from './CustomComponent'
import SelectComponent from './Select'

const mapping = {
  select: SelectComponent,
  checkbox: CheckboxComponent,
  radio: RadioButtonComponent,
  number: NumberComponent,
  string: StringComponent,
  textarea: TextAreaComponent,
  password: PasswordComponent,
  custom: CustomComponent,
}

export class SimpleField extends Component {
  static propTypes = {
    displayInline: PropTypes.bool,
    field: PropTypes.object.isRequired,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  static defaultProps = {
    displayInline: false,
    input: {},
    showLabel: true,
    style: {},
  }

  render() {
    const props = this.props
    const { field, input, showLabel: showLabelProp, displayInline, style } = props
    const { type, showLabel } = field
    const finalShowLabel = showLabel !== undefined ? showLabel : showLabelProp
    const View = mapping[type] || StringComponent
    return (
      <View
        key={field.field}
        displayInline={displayInline}
        field={field}
        input={input}
        showLabel={finalShowLabel}
        style={style}
      />
    )
  }
}
