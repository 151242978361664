import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get } from 'lodash'

import st from './styles.scss'
import OptionsSelector from '../common/form/OptionsSelector'

export default class Dropdown extends Component {
    static propTypes = {
      fieldOptions: PropTypes.object,
      input: PropTypes.object,
    }

    static defaultProps = {
      input: {},
      fieldOptions: {},
    }

    optionsProvider = input => (this.props.fieldOptions.optionsProvider(input))

    getOptions = (key) => {
      if (key === 'OPTIONS') {
        return {
          1366: {
            'background-color': '#ffffff',
            'min-height': '40px',
            'border-bottom': '1px solid #eeeeee',
            height: '40px !important',
          },
          1920: {
            'background-color': '#ffffff',
            'min-height': '40px',
            'border-bottom': '1px solid #eeeeee',
            height: '40px !important',
          },
        }
      }
      return {
        1366: {
          'background-color': '#ffffff',
          'min-height': '10px',
          height: '40px !important',
        },
        1920: {
          'background-color': '#ffffff',
          'min-height': '20px',
          height: '40px !important',
        },
      }
    }
    cssStructure

    render() {
      const { input, fieldOptions } = this.props
      const { optionsProvider } = fieldOptions
      return (
        <div className={cs(st.dropdown)}>
          <span>{get(input, 'label')}</span>
          { optionsProvider ? (
            <OptionsSelector
              {...fieldOptions}
              customStyles={{ options: this.getOptions('OPTIONS'),
                controls: this.getOptions('CONTROLS'),
              }}
              field={fieldOptions}
              onBlur={this.onBlur}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onMenuClose={input.onMenuClose}
              onMenuOpen={input.onMenuOpen}
              optionsProvider={this.optionsProvider}
              value={get(input, 'value')} />
          ) :
            (
              <OptionsSelector
                {...fieldOptions}
                customStyles={{ options: this.getOptions('OPTIONS'),
                  controls: this.getOptions('CONTROLS'),
                }}
                field={fieldOptions}
                onBlur={this.onBlur}
                onChange={input.onChange}
                onFocus={input.onFocus}
                onMenuClose={input.onMenuClose}
                onMenuOpen={input.onMenuOpen}
                value={get(input, 'value')} />
            )
            }
        </div>
      )
    }
}
