import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

import styles from './style.scss'

const RIGHT = 'right'
const LEFT = 'left'

export default class Button extends Component {
  static propTypes = {
    action: PropTypes.bool,
    arrowIcon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.element,
    ]),
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    btnClass: PropTypes.string,
    btnStyle: PropTypes.string,
    center: PropTypes.bool,
    disabled: PropTypes.bool,
    highlighted: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.element,
    ]),
    iconClass: PropTypes.string,
    iconPosition: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelClass: PropTypes.string,
    onClick: PropTypes.func,
    showArrow: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
  }

  static defaultProps = {
    action: true,
    arrowIcon: '',
    border: false,
    btnClass: '',
    btnStyle: '',
    boxShadow: false,
    center: false,
    disabled: false,
    icon: '',
    iconClass: '',
    highlighted: false,
    iconPosition: RIGHT,
    label: '',
    labelClass: '',
    onClick: _ => _,
    style: {},
    showArrow: false,
    title: '',
  }

  onClick = e => {
    const { onClick } = this.props
    onClick(e)
  }

  renderIcon = () => {
    const { icon, iconClass, iconPosition } = this.props
    let iconPositionClass = ''
    if (typeof icon === 'function') {
      return icon()
    }
    if (iconPosition === LEFT) {
      iconPositionClass = styles['icon-left']
    }
    if (iconPosition === RIGHT) {
      iconPositionClass = styles['icon-right']
    }
    return <span className={classnames(styles['btn-icon'], iconPositionClass, iconClass)}>{icon}</span>
  }

  render() {
    const { label, icon, iconPosition, onClick, btnClass, btnStyle, style, title,
      boxShadow, border, disabled, center, arrowIcon, showArrow, labelClass, action } = this.props
    const shadowClass = boxShadow ? 'btn-shadow' : ''
    const borderClass = border ? 'btn-border' : ''
    const disableClass = disabled ? 'disabled' : ''
    const disablePointerClass = disabled ? 'disabled-pointer' : ''
    const btnCenterClass = center ? styles['btn-center'] : ''
    const removeCursorPointer = !action ? styles['remove-pointer'] : ''

    return (
      <div className={classnames(styles['btn-container'], 'btn', btnCenterClass, disableClass)}>
        <div
          className={classnames(styles.btn, styles[btnStyle], btnClass, shadowClass, borderClass,
            disablePointerClass, removeCursorPointer)}
          onClick={onClick}
          style={style}
          title={title}
        >
          {icon && iconPosition === LEFT && this.renderIcon()}
          <span className={classnames(styles.label, labelClass)}>{label}</span>
          {icon && iconPosition === RIGHT && this.renderIcon()}
          {showArrow && <span className={styles['arrow-right']}>{arrowIcon}</span>}
        </div>
      </div>
    )
  }
}
