import { lazy } from 'react'

export * from './common'
// export MainContent from './MainContent'
export IntroContent from './IntroContent'
export const UserDashboard =
  lazy(() => import(/* webpackChunkName: "UserDashboard" */'./UserDashboard'))
export const AdminDashboard =
  lazy(() => import(/* webpackChunkName: "AdminDashboard" */'./AdminDashboard'))
export const SupportDashboard =
  lazy(() => import(/* webpackChunkName: "SupportDashboard" */'./SupportDashboard'))
export const Builder =
  lazy(() => import(/* webpackChunkName: "Builder" */'./GuidedResume/Builder'))
export const Preview =
  lazy(() => import(/* webpackChunkName: "Preview" */'./Preview'))
// export Blog from './Blog'
// export CareerGuide from './CareerGuide'
// export Interview from './Interview'
export LoginPage from './LoginPage'
