import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { DASHBOARD_ROUTE, INTRO_ROUTE, BUILDER_ROUTE, PREVIEW_ROUTE, OAUTH_ROUTE, DashboardRoutes, OAuthRoutes,
  MainRoutes } from '.'
  // GUIDEDDASHBOARD_ROUTE, GuidedDashboardRoutes,
// import Intro from '../../containers/IntroContainer'

export const APP_ROUTE_NAME = 'Home'
export const PAGE_NOT_FOUND = '/404'

export const AppRoutes = p => (
  <Switch>
    <Route component={DashboardRoutes} path={[DASHBOARD_ROUTE, BUILDER_ROUTE, PREVIEW_ROUTE, INTRO_ROUTE]} />
    <Route component={OAuthRoutes} path={[OAUTH_ROUTE]} />
    <Route component={MainRoutes} path="*" />
  </Switch>
)
