import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get } from 'lodash'

import st from './style.scss'

export default class InputField extends Component {
  static propTypes = {
    input: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const { input } = props
    this.state = {
      value: input.value,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (get(nextProps, 'input.value') !== prevState.value) {
      return {
        value: get(nextProps, 'input.value'),
      }
    }
    return null
  }

  onChange = e => {
    const { input } = this.props
    if (input.onChange) {
      if (get(input, 'type') === 'checkbox'){
        e.target.value = e.target.checked
        input.onChange(e.target.checked)
      }
      else {
        input.onChange(e.target.value)
      }
    }
    this.setState({
      value: e.target.value,
    })
  }

  onKeyPress = e => {
    const { input } = this.props
    if (e.key === 'Enter') {
      if (input.onEnter) {
        input.onEnter(e.target.value)
      }
    }
  }

  getComponent = () => {
    const { input: { type, rows, disabled, placeholder } } = this.props
    const { value } = this.state
    if (type === 'textarea'){
      return (
        <textarea
          className={cs('form-control')}
          disabled={disabled}
          onChange={this.onChange}
          rows={rows}
          value={value} />
      )
    }
    return (
      <input
        checked={value}
        className={cs('form-control')}
        disabled={disabled} onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        placeholder={placeholder}
        type={type || 'text'}
        value={value} />
    )
  }

  render() {
    const { input: { label, suffix } } = this.props
    return (
      <div className={cs(st.input_form_group, st.input_res_15)}>
        {label && <span> {label} </span>}
        {this.getComponent()}
        { suffix && <span> {suffix} </span> }
      </div>
    )
  }
}
