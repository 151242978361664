import { get } from 'lodash'

import { api, paymentApi } from './api-helper'
// import {  form } from './api-helper'
import { SEVERITY, getMappedUrl, prepareFormData, GET_PROFILE_URI, SAVE_PROFILE_URI, LOGIN_USER, REGISTER_USER,
  LOGOUT_USER, CREATE_RESUME, CREATE_SUB_ORDER, UPDATE_SUB_ORDER, PERSONAL_VIEW, VALIDATE_OTP, FETCH_RESUME_DETAILS,
  RESET_PASSWORD, CHANGE_PASSWORD, FETCH_RESUMES, UPDATE_RESUME, FETCH_UPDATE_RESUME, getTemplateMappedUrl,
  FETCH_SAVE_PDF, FETCH_SAVE_IMAGE, FETCH_DASHBOARD, FETCH_BILLING_DETAIL, FETCH_BILLING_LIST,
  DOWNLOAD_RESUME, INITIATE_TRXN, FETCH_RESUME_IMAGES, MESSAGES,
  INITIATE_PAYMENT_GATEWAY_CALL, ADD_TOKEN } from '../constants'
import { ErrorHandlingService } from '.'

export async function loginUser(payload = {}) {
  let response
  try {
    const url = getMappedUrl(LOGIN_USER)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchBillingDetail(id = {}) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_BILLING_DETAIL, [id])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchBillingList() {
  let response
  try {
    const url = getMappedUrl(FETCH_BILLING_LIST)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchDashboard(token) {
  let response
  try {
    const url = getMappedUrl(FETCH_DASHBOARD, { token })
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchUserResumes() {
  let response
  try {
    const url = getMappedUrl(FETCH_RESUMES)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function createSubscriptionOrder(payload = {}) {
  let response
  try {
    const url = getMappedUrl(CREATE_SUB_ORDER)
    response = await api(url, { method: 'POST', body: payload })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function updateSubscriptionOrder(payload = {}) {
  let response
  try {
    const url = getTemplateMappedUrl(UPDATE_SUB_ORDER, [get(payload, 'orderId')])
    response = await api(url, { method: 'POST', body: payload })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function createResumeOrder(templateId) {
  let response
  try {
    const url = getMappedUrl(CREATE_RESUME)
    response = await api(url, { method: 'POST', body: { templateId } })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function deleteResume(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(UPDATE_RESUME, [resumeId])
    response = await api(url, { method: 'DELETE' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function addTokenURL(identifier) {
  let response
  try {
    const url = getTemplateMappedUrl(ADD_TOKEN, [identifier])
    response = await api(url, { method: 'PUT' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function updateResumeOrder(resumeId, payload = {}) {
  let response
  try {
    const url = getTemplateMappedUrl(UPDATE_RESUME, [resumeId])
    response = await api(url, { method: 'POST', body: payload })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function downloadResumeById(resumeId = {}) {
  let response
  try {
    const url = getTemplateMappedUrl(DOWNLOAD_RESUME, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function registerUser(payload = {}) {
  let response
  try {
    const url = getMappedUrl(REGISTER_USER)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function validateOTP(payload = {}) {
  let response
  try {
    const url = getMappedUrl(VALIDATE_OTP)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function logoutUser(payload = {}) {
  let response
  try {
    const url = getMappedUrl(LOGOUT_USER)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
    ErrorHandlingService.showMessage({ message: 'You have been logged out.', severity: SEVERITY.INFO })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getProfile(profileId = '', profileNameId = 0) {
  let response
  try {
    const profile = { profileId, profileNameId }
    const url = getMappedUrl(GET_PROFILE_URI, profile)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function saveProfile(profile = {}, profileId, profileNameId = 0, profileName = 'FREE') {
  let response
  try {
    const url = getMappedUrl(SAVE_PROFILE_URI, { profileId, profileNameId, profileName })
    response = await api(url, {
      method: 'POST',
      body: profile,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function createResume(payload, resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_UPDATE_RESUME, [resumeId])
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumeJson(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_UPDATE_RESUME, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumeDetails(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_RESUME_DETAILS, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function savePdfFile(payload, resumeId = {}) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_SAVE_PDF, [resumeId])
    response = await api(url, { method: 'POST', body: payload })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function saveResumeImage(payload, resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_SAVE_IMAGE, [resumeId])
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumeImage(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_SAVE_IMAGE, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumeImages() {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_RESUME_IMAGES)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchPersonalInfo() {
  let response
  try {
    const url = getMappedUrl(PERSONAL_VIEW)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function savePersonalInfo(payload = {}) {
  let response
  try {
    const url = getMappedUrl(PERSONAL_VIEW)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function changePassword(payload = {}) {
  let response
  try {
    const url = getMappedUrl(CHANGE_PASSWORD)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
    ErrorHandlingService.showMessage({ message: MESSAGES.PASSWORD_CHANGED_SUCCESSFULLY, severity: SEVERITY.INFO })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function resetPassword(payload = {}) {
  let response
  try {
    const url = getMappedUrl(RESET_PASSWORD)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
    ErrorHandlingService.showMessage({ message: MESSAGES.PASSWORD_CHANGED_SUCCESSFULLY, severity: SEVERITY.INFO })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getResetPasswordToken(payload = {}) {
  let response
  try {
    const url = getMappedUrl(RESET_PASSWORD)
    response = await api(url, {
      method: 'PUT',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function initiateTransaction(payload = {}){
  let response
  try {
    const url = getMappedUrl(INITIATE_TRXN)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function initiatePaymentGatewayCall(payload = {}){
  let response
  const formData = prepareFormData(payload)
  try {
    response = await paymentApi(INITIATE_PAYMENT_GATEWAY_CALL, {
      method: 'POST',
      body: formData,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}
