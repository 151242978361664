import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'react-bootstrap'
import cs from 'classnames'
import st from './styles.scss'

export default class StepsToStart extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={12} md={12} sm={12} xl={12}>
            <h1> How to create a Job-Winning Resume </h1>
          </Col>
        </Row>
        <Row className={cs(st.row_box)}>
          <Col lg={4} md={4} sm={12} xl={4}>
            <div className={cs(st.process_icon)}>
              <img
                alt=""
                src="../../../assets/images/process-1.png" />
              <h3> Choose a professionally designed template. </h3>
              <div className={cs(st.line)} />
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xl={4}>
            <div className={cs(st.process_icon)}>
              <img
                alt=""
                src="../../../assets/images/process-2.png" />
              <h3> Fill out each section using our expert suggestions. </h3>
              <div className={cs(st.line)} />
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xl={4}>
            <div className={cs(st.process_icon)}>
              <img
                alt=""
                src="../../../assets/images/process-3.png" />
              <h3> Tailor your resume with a few clicks for any job. </h3>
            </div>
          </Col>
        </Row>
        <div className={cs(st.btn_row)}>
          <Button
            className={cs(st.btn_next)}
            onClick={this.props.onNext}> GET Started </Button>
        </div>
      </Container>
    )
  }
}
