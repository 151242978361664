import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { Icon, IconType } from '../common'
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL } from '../../constants'
import styles from './style.scss'

class SocialLogin extends Component {
  static propTypes = {
    launchDashboard: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
  }

  handleSocialLogin = (response) => {
    const { register, launchDashboard } = this.props
    const user = {
      name: get(response, 'profile.name'),
      email: get(response, 'profile.email'),
      picture: get(response, 'profile.profilePicURL'),
    }
    const cb = (resp) => {
      launchDashboard()
    }
    register(user, cb)
  }

  handleSocialLoginFailure = (user) => {
  }

  renderSocialLoginLinks() {
    const { FACEBOOK, GOOGLE } = IconType.SOCIAL
    return (
      <Fragment>
        <div className={classnames(styles.login_div)}>
          <div className={classnames(styles.title, styles.login_icon_img)}>
            <Icon
              color={'#6c757dd1'}
              height={'20'}
              names={[GOOGLE]}
              viewHeight={'24'}
              viewWidth={'24'} width={'20'} />
          </div>
          <div className={classnames(styles.login_content)}>
            <a href={GOOGLE_AUTH_URL}>
              Login with Google
            </a>
          </div>
        </div>
        <div className={classnames(styles.login_div)}>
          <div className={classnames(styles.title, styles.login_icon_img)}>
            <Icon
              color={'#6c757dd1'}
              height={'28'}
              names={[FACEBOOK]}
              viewHeight={'24'}
              viewWidth={'24'} width={'28'} />
          </div>
          <div className={classnames(styles.login_content)}>
            <a href={FACEBOOK_AUTH_URL}>
              Login with Facebook
            </a>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      this.renderSocialLoginLinks()
    )
  }
}

export default withRouter(SocialLogin)
