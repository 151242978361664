import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { Col, Button, Container, Row } from 'react-bootstrap'
import Footer from './Footer'
import { validateEmail, validatePassword } from '../../constants'
import styles from './style.scss'

class Signup extends Component {
  static propTypes = {
    openURL: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      contactNumber: '',
      password: '',
      confPassword: '',
      disableAccountCreation: false,
      errorMessage: '',
    }
  }

  updateField = (key, value) => {
    this.setState({
      [key]: value,
    }, this.validateValues)
  }

  validateValues = () => {
    const { password, confPassword, email } = this.state
    let isValid = true
    const emailValid = validateEmail(email)
    const isStrongPassword = validatePassword(password)
    let errorMessage = ''
    if (!emailValid){
      errorMessage = 'Please enter valid email'
      isValid = false
    }
    else if (password === '') {
      errorMessage = 'Please enter valid password'
      isValid = false
    }
    else if (!isStrongPassword){
      errorMessage = 'Password must contain minimum 8 characters including atleast 1 number and 1 capital letter'
      isValid = false
    }
    else if (password !== confPassword) {
      errorMessage = 'Password you entered do not match. Please enter the same password.'
      isValid = false
    }
    if (errorMessage !== get(this.state, 'errorMessage')) {
      this.setState({
        // disableAccountCreation: disable,
        errorMessage,
      })
    }
    return isValid
  }

  registerUser = () => {
    const { registerUser } = this.props
    const { email, contactNumber, password, firstName, lastName } = this.state
    if (this.validateValues()){
      this.setState({
        disableAccountCreation: true,
      })
      registerUser({ email, firstName, lastName, contactNumber, password })
      this.setState({
        disableAccountCreation: false,
      })
    }
  }

  render(){
    const { email, firstName, lastName, password,
      confPassword, disableAccountCreation, errorMessage } = this.state
    const { openURL } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className={classnames(styles.pp_0)} lg={6} md={6} sm={12} xl={7} xs={12}>
              <div className={classnames(styles.welcome_bg, styles.signup_login)} />
            </Col>
            <Col lg={6} md={6} sm={12} xl={5} xs={12}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.login_title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}>
                      <img
                        alt={'logo'} className={classnames(styles.logo_img_login)}
                        src={'assets/images/logo.svg'} />
                    </div>
                    <span> Sign Up </span>
                  </div>
                  <div className={classnames(styles.welcome_back_title)}>
                    Get Access to Resumaker !
                  </div>
                  <div>
                    <Row>
                      <Col className={classnames(styles.pp_right_5)} lg={6} md={6} sm={12} xl={6} xs={12}>
                        <input
                          className={classnames(styles.login_input_forgot)}
                          id="firstName" name="firstName"
                          onChange={(e) => this.setState({ firstName: e.target.value })}
                          placeholder="Enter your first name" type="text"
                          value={firstName} />
                      </Col>
                      <Col className={classnames(styles.pp_left_5)} lg={6} md={6} sm={12} xl={6} xs={12}>
                        <input
                          className={classnames(styles.login_input_forgot)}
                          id="lastName"
                          name="lastName"
                          onChange={(e) => this.setState({ lastName: e.target.value })}
                          placeholder="Enter your last name" type="text"
                          value={lastName} />
                      </Col>
                    </Row>
                    <input
                      className={classnames(styles.login_input_forgot)} id="email"
                      name="email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Enter your email"
                      type="email"
                      value={email} />
                    <input
                      className={classnames(styles.login_input_forgot)} id="password"
                      name="password"
                      onChange={(e) => this.setState({ password: e.target.value })}
                      placeholder="Enter your password"
                      type="password"
                      value={password} />
                    <input
                      className={classnames(styles.login_input_forgot)} id="confPassword"
                      name="confPassword"
                      onChange={(e) => this.setState({ confPassword: e.target.value })}
                      placeholder="Re-enter your password"
                      type="password"
                      value={confPassword} />
                    <div className={classnames(styles.alert_email)}>
                      {errorMessage}
                    </div>
                    {/* <div className={classnames(styles.alert_password)}>
                      Please Enter an Valid Password
                    </div>
                    <div className={classnames(styles.alert_match_password)}>
                      Your Password is not matching with the Above. Please Enter the same password.
                    </div> */}
                    {/* <div className={classnames(styles.logged_in)}>
                      <div>
                        I agree all terms and conditions
                      </div>
                    </div> */}
                    <Button
                      className={classnames(styles.btn_loginpage_submit)}
                      disabled={disableAccountCreation}
                      onClick={this.registerUser}
                      type="submit"> Create Account </Button>
                  </div>
                  <div className={classnames(styles.dont_have_account,
                    styles.login_create_new_one, styles.show_cursor)}>
                    Already have an Account ? <span onClick={() => openURL('login-with-email')}> Login here. </span>
                  </div>
                </div>
              </div>
            </Col>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(Signup)
