import React, { Component } from 'react'
import { Icon, IconType } from '..'

export default class TickIcon extends Component {
  render() {
    const { TICK } = IconType.BUILDER
    return (
      <Icon
        color={'#28a745'} height={'12'}
        names={[TICK]} viewHeight={'24'}
        viewWidth={'24'} width={'12'} />
    )
  }
}
