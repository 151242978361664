import { get } from 'lodash'

import { api } from './api-helper'
import { ADMIN_GERENAL_REPORT, SEVERITY, getTemplateMappedUrl, FETCH_SUBSCRIBERS, FETCH_ORDERS_REPORT,
  FETCH_RESUMES_REPORT, FETCH_SUBSCRIPTION_REPORT, FETCH_USERS, FETCH_VISITORS, FETCH_DETAILS, getMappedUrl,
  SEARCH_RESUMES, FETCH_RESUME_ADMIN, FETCH_RESUME_IMAGE_ADMIN, SEND_PROMO_EMAILS } from '../constants'
import { ErrorHandlingService } from '.'

export async function fetchSubscribers(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_SUBSCRIBERS, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getAdminsGeneralReportData(startDate = '', endDate = '', hours = '') {
  let response
  try {
    //  const url = '/api/admin/report/graph/10-05-2019/10-10-2021/'
    const url = getTemplateMappedUrl(ADMIN_GERENAL_REPORT, [startDate, endDate, hours])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchOrdersReport(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_ORDERS_REPORT, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumesReport(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_RESUMES_REPORT, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchSubscriptionReport(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_SUBSCRIPTION_REPORT, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchUsers(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_USERS, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchVisitors(startDate = '', endDate = '') {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_VISITORS, [startDate, endDate])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchDetails(apiPart, apiId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_DETAILS, [apiPart, apiId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function searchResumes(userName, resumeName) {
  let response
  try {
    const url = getMappedUrl(SEARCH_RESUMES, { user: userName, resumeName })
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchResumeByAdmin(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_RESUME_ADMIN, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchImageByAdmin(resumeId) {
  let response
  try {
    const url = getTemplateMappedUrl(FETCH_RESUME_IMAGE_ADMIN, [resumeId])
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function sendPromoEmails(payload) {
  let response
  try {
    const url = getMappedUrl(SEND_PROMO_EMAILS)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}
