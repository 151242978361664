import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { set, map, get, isEqual, findIndex, find } from 'lodash'
import { inject } from 'mobx-react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import cs from 'classnames'
import st from './styles.scss'
import Dropdown from './Dropdown'
import { validateEmail, validateNumber, prepareSocialLinkUrls } from '../../constants'

@inject('utilitiesModel')
export default class Contact extends Component {
  static propTypes = {
    onBack: PropTypes.func.isRequired,
    personalInfo: PropTypes.object.isRequired,
    updatePersonalInfo: PropTypes.func.isRequired,
    utilitiesModel: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      personalInfo: props.personalInfo,
      errorMsg: '',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (!isEqual(nextProps.personalInfo, prevState.personalInfo)) {
      return { personalInfo: nextProps.personalInfo }
    }
    return null
  }

  createOption = (key, value, contentKey) => {
    const { createOptions } = this.state
    set(createOptions, `${key}.content`, value)
    set(createOptions, `${key}.value`, { [contentKey]: value })
    this.setState({
      createOptions,
    })
  }

  updateField = (key, value, contentKey) => {
    const { personalInfo } = this.state
    if (contentKey) {
      set(personalInfo, `${key}`, get(value, contentKey, ''))
    }
    else {
      set(personalInfo, `${key}`, value)
    }
    this.setState({
      personalInfo,
    })
  }

  validateFields=(personalInfo) => {
    let isValid = true
    const { errorMsg } = this.state
    let errorMessage = ''
    const isValidEmail = validateEmail(personalInfo.email)
    const isValidNumber = validateNumber(personalInfo.mobile)
    if (!get(personalInfo, 'basicDetails.fName')){
      errorMessage = 'First Name is required'
      isValid = false
    }
    else if (!get(personalInfo, 'basicDetails.lName')){
      errorMessage = 'Last Name is required'
      isValid = false
    }
    else if (!(personalInfo.email && isValidEmail)){
      errorMessage = 'Please enter a valid email'
      isValid = false
    }
    else if (!(personalInfo.mobile && isValidNumber)){
      errorMessage = 'Phone number is required'
      isValid = false
    }
    else if (!personalInfo.city){
      errorMessage = 'City is required'
      isValid = false
    }
    if (errorMessage !== errorMsg){
      this.setState({
        errorMsg: errorMessage,
      })
    }
    return isValid
  }

  setPersonalInfo = (key, value, contentKey) => {
    const { personalInfo } = this.state
    if (contentKey) {
      set(personalInfo, `${contentKey}.${key}`, value)
    }
    else {
      set(personalInfo, key, value)
    }
    this.setState({
      personalInfo,
    })
  }

  addSocialUrl = (socialName, socialUrl) => {
    // console.log('Social URL ', socialUrl)
    // const regex = /(https|http):\/\/((\d+\.?)+|((?:()|www.)[0-z]+\.[a-z]+))\//g
    // let url = socialUrl.replace(regex, '')
    // url = url.replace('in/', '')
    const { personalInfo } = this.state
    let socialLinks = get(personalInfo, 'socialNetworkList')
    if (socialLinks) {
      const index = findIndex(socialLinks, { networkName: socialName })
      if (index >= 0){
        socialLinks.splice(index, 1)
      }
    }
    else {
      socialLinks = []
    }
    socialLinks.push({ networkName: socialName, networkUrl: socialUrl })
    set(personalInfo, 'socialNetworkList', socialLinks)
    this.setState({
      personalInfo,
    })
  }

  getSocialUrl = (socialName) => {
    const { personalInfo } = this.state
    const socialLinks = get(personalInfo, 'socialNetworkList')
    const details = find(socialLinks, { networkName: socialName })
    return get(details, 'networkUrl', '')
  }

  onNext = () => {
    const { updatePersonalInfo } = this.props
    const { personalInfo } = this.state
    if (this.validateFields(personalInfo)){
      const socialLinks = prepareSocialLinkUrls(get(personalInfo, 'socialNetworkList'))
      set(personalInfo, 'socialNetworkList', socialLinks)
      updatePersonalInfo(personalInfo, true)
    }
  }

  render() {
    const { personalInfo, errorMsg } = this.state
    const { utilitiesModel } = this.props
    return (
      <Container className={cs(st.intro_contact_details)}>
        <Row>
          <Col lg={12} md={12} sm={12} xl={12}>
            <h1> Tell us your contact details ?</h1>
            <p className={cs(st.contact_details)}>
              These details will apprear on your resume.</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xl={1} />
          <Col className={cs(st.center)} lg={12} md={12} sm={12} xl={9}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={6} md={12} sm={12}>
                  <Form.Label>First Name *</Form.Label>
                  <input
                    autoComplete="off"
                    className={cs(st.input_control, st.outline)} id="fName" name="fName"
                    onChange={(e) => this.setPersonalInfo('fName', e.target.value, 'basicDetails')}
                    placeholder="" type="text" value={get(personalInfo, 'basicDetails.fName')} />
                </Form.Group>
                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={6} md={12} sm={12}>
                  <Form.Label>Last Name *</Form.Label>
                  <input
                    autoComplete="off"
                    className={cs(st.input_control, st.outline)} id="lName" name="lName"
                    onChange={(e) => this.setPersonalInfo('lName', e.target.value, 'basicDetails')}
                    placeholder="" type="text" value={get(personalInfo, 'basicDetails.lName')} />
                </Form.Group>
                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={4} md={12} sm={12}>
                  <Form.Label>Email *</Form.Label>
                  <input
                    autoComplete="off"
                    className={cs(st.input_control, st.outline)} id="email" name="email"
                    onChange={(e) => this.setPersonalInfo('email', e.target.value)}
                    placeholder="" type="text" value={get(personalInfo, 'email')} />
                </Form.Group>
                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={4} md={12} sm={12}>
                  <Form.Label>Phone Number  *</Form.Label>
                  <input
                    autoComplete="off"
                    className={cs(st.input_control, st.outline)} id="mobile" name="mobile"
                    onChange={(e) => this.setPersonalInfo('mobile', e.target.value)}
                    placeholder="" type="text" value={get(personalInfo, 'mobile')} />
                </Form.Group>

                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={4} md={12} sm={12}>
                  <Form.Label>City  *</Form.Label>
                  <div className={cs(st.dropdown_shadow)}>
                    <Dropdown
                      fieldOptions={{
                        optionsProvider: (input, form) => utilitiesModel.getCities(input).then(response => {
                          const cityArray = response || []
                          const results = map(cityArray, result => ({
                            value: result,
                            label: result.content,
                          }))
                          return results
                        }),
                        labelKey: 'label',
                        valueKey: 'value',
                        sortedOptions: true,
                        sortKey: 'label',
                      }}
                      input={{
                        value: { value: get(personalInfo, 'city'), label: get(personalInfo, 'city') },
                        onChange: (city) => {
                          this.updateField('city', get(city, 'value'), 'content')
                        },
                      }} />
                  </div>
                </Form.Group>
                <Form.Group as={Col} className={cs(st.mrg_btn_25)} lg={12} md={12} sm={12}>
                  <Form.Label>LinkedIn</Form.Label>
                  <input
                    autoComplete="off"
                    className={cs(st.input_control, st.outline)} id="linkedInUrl" name="linkedInUrl"
                    onChange={(e) => this.addSocialUrl('LINKEDIN', e.target.value)}
                    placeholder="" type="text" value={this.getSocialUrl('LINKEDIN')} />
                </Form.Group>
              </Form.Row>
            </Form>
            <Row>
              <Col lg={12} md={12} sm={12} xl={9}>
                <div className={cs(st.alert_msg)}>
                  {errorMsg}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={12} xl={1} />
        </Row>
        <Row>
          <div className={cs(st.btn_row)}>
            <Button
              className={cs(st.btn_back)}
              onClick={this.props.onBack}> Back </Button>
            <Button
              className={cs(st.btn_next)}
              onClick={this.onNext}> Continue </Button>
          </div>
        </Row>
      </Container>
    )
  }
}
