import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import st from './styles.scss'

export default class SelectIcon extends Component {
  static propTypes = {
    click: PropTypes.func.isRequired,
    image: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.bool.isRequired,
  }

  render() {
    const { click, label, image, selected } = this.props
    return (
      <div
        className={cs(st.process_icon, { [st.select]: selected })}
        onClick={click}>
        <img alt="" src={image} />
        <p>{label}</p>
      </div>
    )
  }
}
