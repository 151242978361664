import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { get, map } from 'lodash'
import cs from 'classnames'
import { SUBSCRIPTIONPLANS } from '../../constants'
import { Icon, IconType, TickIcon } from '../common'
import st from './styles.scss'

@inject('utilitiesModel')
export default class Pricing extends Component {
  static propTypes = {
    domain: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }

  render() {
    const { subscribe } = this.props
    const { LEFT_ARROW } = IconType.BUILDER
    return (
      <Fragment>
        <div>
          <div className={cs(st.left_arrow)} onClick={this.props.onBack}>
            <div className={cs(st.back_icon)}><Icon
              className={cs(st.img_fluid)} color={'#212121'} height={'12'}
              margin-left={'55px'} names={[LEFT_ARROW]} viewHeight={'512'}
              viewWidth={'512'} width={'24'} /></div>
            <span>Back </span>
          </div>
          <Container className={cs(st.upgrade_box)}>
            <Row className={cs('mb-2')}>
              <Col lg={12} md={12} sm={12} xl={12}>
                <h3>Please select a subscription plan</h3>
              </Col>
            </Row>
            <div className={cs(st.package_boxes)}>
              <div className={cs(st.package_one)}>
                <h6>{get(SUBSCRIPTIONPLANS.FREE, 'name')} </h6>
                <h3><span className={cs(st.rupee_icon)}>&#8377;
                </span> {get(SUBSCRIPTIONPLANS.FREE, 'value')}
                  <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.FREE, 'sub_note')}</span></h3>
                <ul>
                  {map(SUBSCRIPTIONPLANS.FREE.features, (value, key) => (
                    <li>
                      <TickIcon />
                      <p> {value}</p>
                    </li>
                  ))}
                </ul>
                <Button
                  className={cs(st.btn, st.btn_warning, st.btn_select_package)}
                  onClick={() => subscribe(get(SUBSCRIPTIONPLANS.FREE, 'id'))}>CONTINUE</Button>
              </div>
              <div className={cs(st.package_two)}>
                <h6>{get(SUBSCRIPTIONPLANS.MONTHLY, 'name')}</h6>
                <h3><span className={cs(st.rupee_icon)}>&#8377;
                </span> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')}<span className={cs(st.offer_size)}>
                  /{get(SUBSCRIPTIONPLANS.MONTHLY, 'sub_note')}</span></h3>
                {/* <h3> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')} </h3> */}
                <ul className={cs(st.image_content_listing)}>
                  {map(SUBSCRIPTIONPLANS.MONTHLY.features, (value, key) => (
                    <li>
                      <TickIcon />
                      <p> {value}</p>
                    </li>
                  ))}
                </ul>
                <Button
                  className={cs(st.btn, st.btn_warning, st.btn_select_package)}
                  onClick={() => subscribe(get(SUBSCRIPTIONPLANS.MONTHLY, 'id'))}>CONTINUE</Button>
              </div>
              <div className={cs(st.package_three)}>
                <h6>{get(SUBSCRIPTIONPLANS.PAYASUGO, 'name')}</h6>
                <h3><span className={cs(st.rupee_icon)}>&#8377;
                </span> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')}<span className={cs(st.offer_size)}>
                  /{get(SUBSCRIPTIONPLANS.PAYASUGO, 'sub_note')}</span></h3>
                {/* <h3> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')} </h3> */}
                <ul>
                  {map(SUBSCRIPTIONPLANS.PAYASUGO.features, (value, key) => (
                    <li>
                      <TickIcon />
                      <p> {value}</p>
                    </li>
                  ))}
                </ul>
                <Button
                  className={cs(st.btn, st.btn_warning, st.btn_select_package)}
                  onClick={() => subscribe(get(SUBSCRIPTIONPLANS.PAYASUGO, 'id'))}>CONTINUE</Button>
              </div>
            </div>
          </Container>
        </div>
      </Fragment>
    )
  }
}
