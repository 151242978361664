import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// import Icon from '@mdi/react'
// import { mdiCheck } from '@mdi/js'

import styles from './style.scss'

export default class Selection extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    value: PropTypes.string.isRequired,

  }

  static defaultProps = {
    selected: false,
  }

  onChange = e => {
    const { value, selected, onChange } = this.props
    onChange(!selected, value)
  }

  render() {
    const { label, selected } = this.props
    return (
      <div
        className={classnames(styles.select_button, { [styles.selected]: selected })}
        onClick={this.onChange}>
        {selected
		// && (
        // <Icon
        //   path={mdiCheck}
        //   size={1} />
        // )
		}
        {label}
      </div>
    )
  }
}
