import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { omit } from 'lodash'

import { getLabelStyle, getContainerStyle, getInputContainerStyle } from './Helpers'

import styles from './style.scss'

export default class NumberComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
  }

  constructor(props) {
    super(props)
    const { input } = props
    this.state = {
      value: input.value,
    }
  }

  componentDidUpdate(prevProps) {
    const { input, formField } = this.props
    if (this.state.value !== ((formField && formField.value) || input.value)) {
      this.setState({
        value: formField ? formField.value : input.value,
      })
    }
  }

  onChange = e => {
    const updatedValue = e.target.value
    const { input, formField } = this.props
    let value = formField ? formField.value : updatedValue
    value = value || ''
    // Hack applied for IE.
    // input type number is not supported by IE.
    if (isNaN(updatedValue)) {
      e.target.value = value.replace(/[\D]/g, '')
    }

    if (updatedValue === '') {
      this.numberInput.value = ''
    }
    if (formField && formField.onChange) {
      formField.onChange(e)
    }
    if (input.onChange) {
      input.onChange(e)
    }
    this.setState({
      value: e.target.value,
    })
  }

  onBlur = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onBlur) {
      input.onBlur({ value: e.target.value, ...extraParameters })
    }
  }

  onKeyDown = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onKeyDown) {
      input.onKeyDown({ e, ...extraParameters })
    }
  }

  setInputRef = ref => {
    this.numberInput = ref
  }

  getTitle = () => {
    const { field } = this.props
    return (field && field.title) || field.label
  }

  render() {
    const props = this.props
    const { field, showLabel, formField } = props
    const { value } = this.state
    const {
      label, placeholder, editable, readOnly = false, type: fieldType,
    } = field

    // Hack applied for IE.
    // input type number is not supported by IE.
    const type = 'text'
    let { input } = props
    input = omit(input, ['name'])
    input.value = value
    const disabled = field.disabled
    const inputContainerClassName =
      classnames(getInputContainerStyle(field), { [styles['read-only']]: readOnly }, 'form-error')
    return (
      <div className={getContainerStyle(field)}>
        {showLabel && <div className={classnames(getLabelStyle(field), 'label')} title={this.getTitle()}>{label}</div>}
        {editable === false ?
          <div className={styles['non-editable-value']}>{input.value}</div> : (
            <div className={inputContainerClassName}>
              {fieldType === 'currency' ? <div className={styles['currency-sign']}>$</div> : ''}
              {!formField ? (
                <input
                  ref={this.setInputRef}
                  {...input}
                  className={styles.input}
                  disabled={disabled}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyDown={this.onKeyDown}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  type={type}
              />
              ) : (
                <input
                  ref={this.setInputRef} {...formField.bind()} className={styles.input}
                  disabled={disabled} onChange={this.onChange} placeholder={placeholder}
                  readOnly={readOnly} type={type} />
          ) // eslint-disable-line
            }
            </div>
          )}
      </div>
    )
  }
}
