import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Button } from 'react-bootstrap'
import cs from 'classnames'
import { Icon, IconType } from '../common'
import st from './styles.scss'

export default class GetStart extends Component {
  static propTypes = {
    initiateResumeCreation: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
  }

  render() {
    const { initiateResumeCreation } = this.props
    const { LEFT_ARROW } = IconType.BUILDER
    return (
      <div>
        <div className={cs(st.left_arrow)} onClick={this.props.onBack}>
          <div className={cs(st.back_icon)}><Icon
            className={cs(st.img_fluid)} color={'#212121'} height={'12'}
            margin-left={'55px'} names={[LEFT_ARROW]} viewHeight={'512'}
            viewWidth={'512'} width={'24'} /></div>
          <span>Back </span>
        </div>
        <Container className={cs(st.lauch_button)} fluid>
          <Row>
            <Button
              className={cs(st.btn_next)}
              onClick={initiateResumeCreation}> Launch Builder </Button>
          </Row>
        </Container>
      </div>
    )
  }
}
