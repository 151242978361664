import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isFunction, get } from 'lodash'

import { getLabelStyle } from './Helpers'
import styles from './style.scss'

export default class RadioButton extends React.Component {
  static propTypes = {
    displayInline: PropTypes.bool,
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    id: PropTypes.string,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.string,
  }

  static defaultProps = {
    displayInline: false,
    formField: undefined,
    input: {},
    showLabel: true,
    id: '',
    style: '',
  }

  constructor(props) {
    super(props)
    this.formField = props.formField ? props.formField.bind() : undefined
    this.state = {
      value: this.getValue(),
    }
  }

  onChange = e => {
    const { input, formField } = this.props
    let radio_val
    if (e && e.target && e.target.value) {
      if (e.target.value === 'true' || e.target.value === 'false') {
        radio_val = JSON.parse(e.target.value)
      }
      else {
        radio_val = e.target.value
      }
      this.setState({
        value: radio_val,
      })
    }

    if (input.onChange && isFunction(input.onChange)) {
      input.onChange(e)
    }
    if (formField && formField.onChange && isFunction(formField.onChange)) {
      formField.onChange(radio_val)
    }
  }

  getValue = () => {
    const { field } = this.props
    return field.value
  }

  getLabel = () => {
    const { field } = this.props
    return field.label
  }

  render() {
    const { field, input, showLabel, displayInline, style } = this.props
    const { type, forLabel, options } = field
    const { value } = this.state
    const radioName = field.name || ''
    const finalLabel = this.getLabel()
    const forLabelId = forLabel || finalLabel
    const labelClassName = field.labelClass || getLabelStyle(field)
    const className = classnames(styles['radio-button'])
    return (
      <div className={className}>
        { options && options.length > 0 && (
          <div>
            <span>
              {
                showLabel &&
                <label className={getLabelStyle(field)} htmlFor={finalLabel}>{finalLabel}</label>
              }
            </span>
            <div className={classnames({ 'radio-button-inline': displayInline })}>
              {options.map(option => {
                const checked = get(option, 'value', option) === value
                return (
                  <div key={get(option, 'value', option)} className={classnames(style, 'radiobutton-container')}>
                    <input
                      {...input}
                      {...field}
                      checked={checked}
                      id={`${field.field}${finalLabel}${get(option, 'value', option)}`}
                      name={field.field}
                      onChange={this.onChange}
                      type={type}
                      value={get(option, 'value', option)}
                    />
                    {<label className={getLabelStyle(field)} htmlFor={`${field.field}${finalLabel}${get(option, 'value', option)}`}>
                      {get(option, 'label', option)}</label>}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {!options && (
        <div className={style}>
          <input
            {...input}
            {...field}
            id={`${radioName}_${finalLabel}`}
            type={type}
          />
          {showLabel && <label className={labelClassName} htmlFor={`${radioName}_${forLabelId}`}>{finalLabel}</label>}
        </div>
        )
        }
      </div>
    )
  }
}
