import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactSelect from 'react-select'
import Creatable from 'react-select/creatable'
import AsyncCreatable from 'react-select/async-creatable'
import Async from 'react-select/async'
import { isNil, isEmpty, sortBy, some, get, map } from 'lodash'

import MenuRenderer, { DUMMY_OPTION } from './MenuRenderer'
import st from './style.scss'

export default class OptionsSelector extends Component {
  static propTypes = {
    appendToBody: PropTypes.bool,
    async: PropTypes.bool,
    autoload: PropTypes.bool,
    customStyles: PropTypes.object,
    clearable: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    creator: PropTypes.func,
    disabled: PropTypes.bool,
    footer: PropTypes.node,
    labelKey: PropTypes.string,
    loadOnRender: PropTypes.bool,
    loadOptions: PropTypes.func,
    matchPos: PropTypes.oneOf(['any', 'start']),
    minInputLength: PropTypes.number,
    multi: PropTypes.bool,
    name: PropTypes.string,
    noResultsText: PropTypes.string,
    onBlur: PropTypes.func,
    onBlurResetsInput: PropTypes.bool,
    onChange: PropTypes.func,
    onCloseResetsInput: PropTypes.bool,
    onFocus: PropTypes.func,
    onInputKeyDown: PropTypes.func,
    onMenuClose: PropTypes.func,
    onMenuOpen: PropTypes.func,
    openOnClick: PropTypes.bool,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    optionsProvider: PropTypes.func,
    placeholder: PropTypes.string,
    removeSelected: PropTypes.bool,
    searchable: PropTypes.bool,
    selectable: PropTypes.bool,
    showCheckBox: PropTypes.bool,
    sortedOptions: PropTypes.bool,
    sortKey: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool, PropTypes.object]),
    valueKey: PropTypes.string,
  }

  static defaultProps = {
    appendToBody: true,
    async: false,
    autoload: true,
    clearable: true,
    closeOnSelect: true,
    disabled: false,
    footer: undefined,
    loadOnRender: false,
    loadOptions: () => { },
    multi: false,
    minInputLength: 2,
    matchPos: 'any',
    onBlur: () => { },
    onChange: () => { },
    onFocus: () => { },
    onInputKeyDown: () => { },
    onMenuOpen: () => {},
    onMenuClose: () => {},
    options: [],
    optionsProvider: null,
    placeholder: '',
    removeSelected: true,
    searchable: false,
    selectable: true,
    showCheckBox: false,
    sortedOptions: false,
    sortKey: 'label',
    value: '',
    valueKey: 'value',
    labelKey: 'label',
    openOnClick: true,
    noResultsText: '',
    onCloseResetsInput: true,
    onBlurResetsInput: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value || '',
      suggestions: [],
      counter: 1,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }
    return null
  }

  componentWillUnmount = () => {
    this.isUnmounted = true
  }

  setElementRef = ref => {
    this.elementRef = ref
  }

  handleOnChange = selected => {
    this.setState({
      suggestions: selected,
    })
    this.setState(prevState => ({ counter: prevState.counter + 1 }))
    if (this.props.selectable) {
      this.setState({ value: selected })
    }
    this.props.onChange(selected)
  }

  autoSuggest = (input, callback) => {
    const { minInputLength, labelKey, valueKey } = this.props
    if (input.length < minInputLength){
      const arr = []
      arr.push({
        [labelKey]: input,
        [valueKey]: input,
      })
      callback(arr)
      return
    }
    const self = this
    const transformer = (obj, cb) => {
      if (this.isUnmounted || !obj) {
        return
      }
      const arr = []
      obj.map(prop => {
        arr.push({
          ...prop,
          [self.props.labelKey]: prop[self.props.labelKey],
          [self.props.valueKey]: prop[self.props.valueKey],
        })
        return prop
      })
      if (self.props.footer) {
        arr.push({
          [self.props.labelKey]: DUMMY_OPTION,
          [self.props.valueKey]: DUMMY_OPTION,
        })
      }
      cb(arr)
    }

    const { optionsProvider } = this.props
    optionsProvider(input).then(jsonResponse => transformer(jsonResponse, callback))
  }

  pushDummyOption = options => {
    const { footer, labelKey, valueKey } = this.props
    if (isNil(footer) || isEmpty(footer)) {
      return options
    }
    const modifiedOptions = [...options]
    modifiedOptions.push({
      [labelKey]: DUMMY_OPTION,
      [valueKey]: DUMMY_OPTION,
    })
    return modifiedOptions
  }

  filterOptions = (options, filter) => {
    if (isNil(filter) || isEmpty(filter)) {
      return options
    }
    const { labelKey } = this.props
    const modifiedOptions = options && options.filter(option => {
      const optionValue = option[labelKey] && option[labelKey].toLowerCase()
      return (optionValue && optionValue.indexOf(filter.toLowerCase()) === 0)
    })
    return this.pushDummyOption(modifiedOptions)
  }

  menuRenderer = () => {
    const { footer, value, appendToBody } = this.props
    return menuRendererProps => (
      <MenuRenderer
        {...menuRendererProps}
        appendToBody={appendToBody}
        elementRef={this.elementRef}
        footer={footer}
        options={value}
      />
    )
  }

  updateCounter = () => {
    this.setState(prevState => ({ counter: prevState.counter + 1 }))
  }

  sortOptionList = option => sortBy(option, [o => o[this.props.sortKey]])

  optionRenderer = option => {
    const { showCheckBox, value, labelKey, valueKey } = this.props
    const isChecked = some(this.state.suggestions, [labelKey, option[labelKey]]) ||
      some(value, [valueKey, option[valueKey]])
    if (showCheckBox && this.state.counter) {
      return (
        <div className={classnames('checkbox', 'dropdown-checkbox')}>
          <label className={'checkbox-container'}>
            <input
              key={this.state.counter}
              checked={isChecked || option.disabled}
              className={classnames('operation_input')}
              className={'css-checkbox'}
              onChange={this.updateCounter}
              type={'checkbox'}
            />
            <i />
            <span>{option[labelKey]}</span>
          </label>
        </div>
      )
    }
    return option[labelKey]
  }

  loadDefaultOptions = () => {
    const { value } = this.props
    this.setState({ defaultOptions: [value] })
  }

  onInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      if (inputValue === ''){
        this.setState({ defaultOptions: [] })
      }
    }
    if (action === 'menu-close') {
      this.loadDefaultOptions(this.state.inputValue)
    }
  }

  getOptionCSS = (innerStyles, { data, isDisabled, isFocused, isSelected }, customStyles) => {
    const { width } = window.screen
    let updatedStyles = {}
    map(customStyles, (value, key) => {
      if (width >= key){
        updatedStyles = {
          ...innerStyles,
          ...value,
          '&:hover': {
            'border-bottom': isFocused ? '2px solid #f6f6f6' : '',
            'background-color': isFocused ? '#fffff' : '',
          },
        }
      }
    })
    return updatedStyles
  }

  handleCreate = (inputValue) => {
    const { creator } = this.props
    creator(inputValue)
  }

  getControlCSS = (provided, state, customStyles) => {
    const { width } = window.screen
    let updatedStyles = {}
    map(customStyles, (value, key) => {
      if (width >= key){
        updatedStyles = {
          ...provided,
          ...value,
          border: state.isFocused ? 0 : 0,
          boxShadow: state.isFocused ? 0 : 0,
          '&:hover': {
            border: state.isFocused ? 0 : 0,
          },
        }
      }
    })
    return updatedStyles
  }

  render() {
    const { sortedOptions, customStyles } = this.props
    const { defaultOptions } = this.state
    const { optionsProvider, value, creatable, disabled } = this.props
    const styles = {
      control: (provided, state) => (
        this.getControlCSS(provided, state, get(customStyles, 'controls'))
      ),
      option: (innerStyles, { data, isDisabled, isFocused, isSelected }) => (
        this.getOptionCSS(innerStyles, { data, isDisabled, isFocused, isSelected }, get(customStyles, 'options'))
      ),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1
        const transition = 'opacity 300ms'
        return { ...provided, opacity, transition }
      },
    }
    let options = this.pushDummyOption(creatable ? [] : this.props.options)
    options = sortedOptions ? this.sortOptionList(options) : options
    const editable = !disabled
    if (creatable) {
      return (
        <div ref={this.setElementRef} onFocus={this.handleFocus}>
          {!optionsProvider ?
            (
              <Creatable
                {...this.props}
                filterOptions={this.props.footer && this.filterOptions}
                menuRenderer={this.menuRenderer()}
                options={options}
                value={value} />
            ) :
            (
              <AsyncCreatable
                autoload={this.props.autoload}
                filterOptions={this.props.footer && this.filterOptions}
                loadOptions={this.autoSuggest}
                menuRenderer={this.menuRenderer()}
                noResultsText={this.props.noResultsText}
                onChange={this.handleOnChange}
                onCreateOption={this.handleCreate}
                openOnClick={this.props.openOnClick}
                placeholder={this.props.placeholder}
                value={value}
              />
            )
          }
        </div>
      )
    }
    return (
      <div ref={this.setElementRef} className={classnames(st.selector, 'flex-auto')} onFocus={this.handleFocus}>
        {!optionsProvider ?
          (
            <ReactSelect
              className={'react-select-container'}
              classNamePrefix={'react-select'}
              {...this.props}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              editable={editable}
              filterOptions={this.filterOptions}
              isDisabled={disabled}
              matchProp={'label'} // restrict dropdown search only to label
              menuRenderer={this.menuRenderer()}
              onChange={this.handleOnChange}
              onClose={this.props.onMenuClose}
              onOpen={this.props.onMenuOpen}
              optionRenderer={this.optionRenderer}
              options={options}
              value={value}
          />
          ) :
          (
            <Async
            // menuIsOpen={true}
              autoload={this.props.autoload}
              closeOnSelect={this.props.closeOnSelect}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              defaultOptions={defaultOptions}
              editable={editable}
              filterOptions={this.filterOptions}
              isDisabled={disabled}
              loadOptions={this.autoSuggest}
              matchPos={this.props.matchPos}
              matchProp={'label'}
              menuRenderer={this.menuRenderer()}
              multi={this.props.multi} // restrict dropdown search only to label
              noResultsText={this.props.noResultsText}
              onChange={this.handleOnChange}
              onClose={this.props.onMenuClose}
              onInputChange={this.onInputChange}
              onOpen={this.props.onMenuOpen}
              openOnClick={this.props.openOnClick}
              optionRenderer={this.optionRenderer}
              placeholder={this.props.placeholder}
              removeSelected={this.props.removeSelected}
              styles={styles}
              value={value}
          />
          )
        }
      </div>
    )
  }
}
