/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
// import classnames from 'classnames'
import { get } from 'lodash'

import { AppConstants } from '../../constants'

// import MainHeader from '../../components/common/MainHeader'
// import MainFooter from '../../components/common/MainFooter'

// import styles from './style.scss'

@inject('mainModel', 'applicationModel')
class SEOContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: 'sample',
  }

  componentDidMount(){
    const { location } = this.props
    const pathname = get(location, 'pathname')
    window.location.assign(`${AppConstants.publicHost}${pathname}`)
  }

  render() {
    const { location } = this.props
    const pathname = get(location, 'pathname')
    window.location.assign(`${AppConstants.publicHost}${pathname}`)
    return (
      <div>
        {/* <MainHeader />
        <MainFooter /> */}
      </div>
    )
  }
}

export default withRouter(SEOContainer)
