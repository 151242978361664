import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isFunction, isEqual } from 'lodash'

import { getLabelStyle, getInputContainerStyle } from './Helpers'

import styles from './style.scss'

const or = (operand, options = []) => {
  let finalOp = false
  options.forEach(option => {
    finalOp = finalOp || isEqual(operand, option)
  })
  return finalOp
}

export default class Checkbox extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.object,
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
    style: {},
  }

  constructor(props) {
    super(props)
    this.formField = props.formField ? props.formField.bind() : undefined
  }

  onChange = e => {
    if (this.formField) {
      // This mess is due to the handling of mobx-react-form.
      // mobx-react-form is taking value from event.target.value.  -: Ritesh
      const event = e
      event.target.value = e.target.checked
      this.formField.onChange(event)
    }
    else {
      const { input } = this.props
      if (input.onChange && isFunction(input.onChange)) {
        input.onChange(e)
      }
    }
  }

  onClick = e => {
    const { input } = this.props
    if (input.onClick && isFunction(input.onClick)) {
      input.onClick(e)
    }
  }

  onBlur = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onBlur && isFunction(input.onBlur)) {
      input.onBlur({ value: e.target.value, ...extraParameters })
    }
  }

  getLabel = () => {
    const { formField, field } = this.props
    return formField ? formField.get('label') : field.label
  }

  getTitle = () => {
    const { field } = this.props
    return (field && field.title) || this.getLabel()
  }

  getValue = () => {
    const { formField, field } = this.props
    return formField ? formField.get('value') : field.value
  }

  render() {
    const props = this.props
    const { field, input, showLabel, style } = props
    const { placeholder, type } = field
    const disabled = field.disabled || input.disabled
    const finalLabel = this.getLabel()
    const className = classnames('checkbox', { disabled }, getInputContainerStyle(field, true))
    const formField = this.formField
    const formFieldValue = formField && this.getValue()
    const formFieldProps = { key: new Date().getTime() }
    if (or(formFieldValue, [true, 'true'])) {
      formFieldProps.checked = true
    }
    return (
      <div className={classnames(className, style)}>
        <label className={'checkbox-container'}>
          {!formField ? (
            <input
              {...input}
              className={classnames(styles.input, 'css-checkbox')}
              disabled={disabled}
              onBlur={this.onBlur}
              onChange={this.onChange}
              placeholder={placeholder}
              type={type}
            />
          ) : (
            <input
              {...formField}
              {...formFieldProps}
              className={classnames(styles.input, 'css-checkbox')}
              disabled={disabled}
              onChange={this.onChange}
              placeholder={placeholder}
              type={type}
              value={this.getValue()} />
          )}
          <i onClick={this.onClick} />
          {showLabel && (
          <strong className={classnames(getLabelStyle(field), 'label')} title={this.getTitle()}>
            {finalLabel}</strong>
          )}
        </label>
      </div>
    )
  }
}
