import React from 'react'
import PropTypes from 'prop-types'
import { omit, debounce } from 'lodash'
import classnames from 'classnames'

import { getLabelStyle, getContainerStyle, getInputContainerStyle } from './Helpers'
import { EMPTY_STRING } from '../../../../constants'

import styles from './style.scss'

export default class StringComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.object,
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
    style: {},
  }

  constructor(props) {
    super(props)
    const { input, formField } = props
    this.state = {
      value: formField ? formField.value : input.value,
    }
    this.throttleChange = debounce(this.onFormFieldChange, 750)
  }

  componentDidUpdate(prevProps) {
    const { input, formField } = this.props
    if (!formField) {
      if (this.state.value !== input.value) {
        this.setState({
          value: input.value,
        })
      }
    }
  }

  onChange = e => {
    const { input } = this.props
    if (input.onChange) {
      input.onChange(e)
    }
    this.setState({
      value: e.target.value,
    })
  }

  onFormFieldChange = e => {
    const props = this.props
    const { formField } = props
    if (formField.onChange) {
      formField.onChange(e)
    }
  }

  debouncedFormFieldChange = e => {
    this.setState({
      value: e.target.value,
    })
    this.throttleChange(e.nativeEvent)
  }

  onBlur = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onBlur) {
      input.onBlur({ value: e.target.value, ...extraParameters })
    }
  }

  onKeyDown = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onKeyDown) {
      input.onKeyDown({ e, ...extraParameters })
    }
  }

  getLabel = () => {
    const { formField, field } = this.props
    if (formField) {
      return formField.get('label')
    }
    return field.label
  }

  getSuffix = () => {
    const { field } = this.props
    return field.suffix
  }

  getTitle = () => {
    const { field } = this.props
    return (field && field.title) || this.getLabel()
  }

  render() {
    const props = this.props
    const { field, formField, showLabel, style } = props
    const { value } = this.state
    const {
      type, placeholder, editable, disabled, toolTip,
    } = field
    const finalLabel = this.getLabel()
    const suffixLabel = this.getSuffix()
    let { input } = props

    input = omit(input, ['name'])
    input.value = value
    const inputContainerClassName = classnames(getInputContainerStyle(field), 'textBox')
    return (
      <div className={classnames(getContainerStyle(field), style)}>
        {showLabel && (
        <div
          className={classnames(getLabelStyle(field), 'label')}
          data-tip={toolTip || EMPTY_STRING}
          title={toolTip ? EMPTY_STRING : this.getTitle()}>
          {finalLabel}
        </div>
        )}
        {editable === false ?
          <div className={styles['non-editable-value']}>{input.value}</div> : (
            <div className={classnames(inputContainerClassName, 'input_wrap')}>
              {!formField ? (
                <input
                  {...input}
                  className={styles.input}
                  disabled={disabled}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyDown={this.onKeyDown}
                  placeholder={placeholder}
                  type={type}
              />
              ) : (
                <input
                {...formField.bind()} // eslint-disable-line
                  className={styles.input}
                  disabled={disabled}
                  onBlur={this.onBlur}
                  onChange={this.debouncedFormFieldChange}
                  placeholder={placeholder}
                  type={type}
                  value={value}
              />
              )}
            </div>
          )}
        {suffixLabel && (
        <div
          className={classnames(getLabelStyle(field), 'label')}
          data-tip={toolTip || EMPTY_STRING}
          title={toolTip ? EMPTY_STRING : this.getTitle()}>
          {suffixLabel}
        </div>
        )}
      </div>
    )
  }
}
