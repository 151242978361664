import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isFunction, get } from 'lodash'

export default class CustomComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
  }

  getControlView = () => {
    const { input, formField, field } = this.props
    return get(input, 'render') || get(field, 'render') || get(formField, 'render')
  }

  getExtraProps = () => {
    const { input, formField, field } = this.props
    return get(input, 'extraProps') || get(field, 'extraProps') || get(formField, 'extraProps') || {}
  }

  render = () => {
    const render = this.getControlView()
    const extraProps = this.getExtraProps()
    return (
      <Fragment>
        {isFunction(render) && render(extraProps)}
      </Fragment>
    )
  }
}
