import React from 'react'
import PropTypes from 'prop-types'
import { isNil, isEmpty } from 'lodash'
import classnames from 'classnames'

import OptionsSelector from '../OptionsSelector'
import { getLabelStyle, getContainerStyle } from './Helpers'
import { EMPTY_STRING } from '../../../../constants'
import styles from './style.scss'

export default class SelectComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  static defaultProps = {
    form: undefined,
    formField: undefined,
    input: {},
    showLabel: true,
    style: {},
  }

  onBlur = e => {
    const { input, field } = this.props
    const event = e
    if (!isNil(input.value) && field.doNotHandleBlur !== true) {
      event.target.value = input.value
    }
    if (input.onBlur) {
      input.onBlur(event)
    }
  }

  optionsProvider = input => (this.props.field.optionsProvider(input, this.props.form))

  renderValues = values => {
    if (!isNil(values) && !isEmpty(values)) {
      return (values.map(value => (
        <div>{value}</div>
      )))
    }
    return <div />
  }

  getTitle = () => {
    const { field } = this.props
    return (field && field.title) || field.label
  }

  render() {
    const props = this.props
    const values = props.input.value
    const { field, formField, input, showLabel, style } = props
    const { editable, suffix, toolTip } = field
    const disabled = input.disabled || field.disabled
    const optionsProvider = field.optionsProvider ? this.optionsProvider : undefined
    return (
      <div className={classnames(getContainerStyle(field), style)}>
        {showLabel && (
          <div className={classnames(getLabelStyle(field), 'label')}>
            {field.label}
          </div>
        )}
        {editable === false ? (
          <div className={styles['non-editable-value']}>
            {this.renderValues(values)}
          </div>
        ) : (
          <div
            className={classnames(styles['select-container'], 'select-container', 'form-error', style)}
            onClick={input.onClick}>
            {!formField ?
              (
                <OptionsSelector
                  {...field}
                  disabled={disabled}
                  field={field}
                  onBlur={this.onBlur}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  onMenuClose={input.onMenuClose}
                  onMenuOpen={input.onMenuOpen}
                  optionsProvider={optionsProvider}
                  value={values}
              />
              ) :
              (
                <OptionsSelector
                  {...field}
                  field={field}
                  onBlur={this.onBlur}
                  onMenuClose={input.onMenuClose}
                  onMenuOpen={input.onMenuOpen}
                  optionsProvider={optionsProvider}
                  value={values}
                {...formField.bind()} //eslint-disable-line
                  disabled={disabled}
              />
              )
            }
          </div>
        )}
        {suffix && (
        <div
          className={classnames(getLabelStyle(field), 'label')}
          data-tip={toolTip || EMPTY_STRING}
          title={toolTip ? EMPTY_STRING : this.getTitle()}>
          {suffix}
        </div>
        )}
      </div>
    )
  }
}
