import React, { Component, Fragment, Suspense } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { replace, get, remove, isFunction } from 'lodash'
import { withRouter } from 'react-router-dom'
import { hasRole, TEMPLATE, SUBSCRIPTIONPLANS, registerForGoogleAnalytics } from '../../constants'
import { Builder, Preview, UserDashboard, IntroContent, AdminDashboard, SupportDashboard, Loader }
  from '../../components'
import { DASHBOARD_KEY_ROUTE, INTRO_ROUTE, BUILDER_KEY_ROUTE, PREVIEW_ROUTE } from '../../router'
import styles from './style.scss'

@inject('profileModel', 'adminModel', 'applicationModel')
@observer
class UserDashboardContainer extends Component {
  static propTypes = {
    adminModel: PropTypes.object.isRequired,
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    profileModel: PropTypes.object.isRequired,
    type: PropTypes.string,
  }

  static defaultProps = {
    type: 'dashboard',
  }

  constructor(props) {
    super(props)
    this.state = {
      type: props.type,
      sideBarMini: false,
      generatingPdf: false,
      isLoading: true,
      notifications: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const selection = get(state, 'type')
    const selectedType = get(props, 'type')
    if (selection !== selectedType) {
      return {
        type: selectedType,
      }
    }
    return null
  }

  componentDidMount() {
    const { history, profileModel, match: { params } } = this.props
    const identifier = get(params, 'id', '')
    const callback = async (userData) => {
      const showIntro = await this.showIntroPage(userData)
      if (showIntro){
        this.updateSelectedKey('', 'intro')
      }
      this.setState({ isLoading: false })
    }
    profileModel.fetchDashboard(identifier, callback)
    registerForGoogleAnalytics(history)
  }

  showIntroPage = async (userData) => {
    const { applicationModel } = this.props
    let showIntro = get(userData, 'intro', false)
    const validSubscription = get(userData, 'subValid', false)
    const redirectedTo = applicationModel.removeRedirectFields(['redirectTo'])
    const planId = get(redirectedTo, 'planId')
    if (get(SUBSCRIPTIONPLANS.FREE, 'id') === planId){
      if (!validSubscription){
        await this.createFreeSubscriptionOrder({ planId }, () => {})
      }
    }
    else if (planId){
      showIntro = false
    }
    return showIntro
  }

  toggleSideBar = () => {
    const { sideBarMini } = this.state
    this.setState({
      sideBarMini: !sideBarMini,
    })
  }

  // eslint-disable-next-line consistent-return
  updateSelectedKey = (selection, type) => {
    const { history, profileModel } = this.props
    let url = ''
    const token = profileModel.getToken()
    if (type === 'builder') {
      if (selection) {
        url = `${replace(BUILDER_KEY_ROUTE, ':key', selection)}`
      }
      else {
        url = `${replace(BUILDER_KEY_ROUTE, ':key', 'objective')}`
      }
      url = `${replace(url, ':id', token)}`
    }
    else if (type === 'preview'){
      url = `${replace(PREVIEW_ROUTE, ':id', token)}`
    }
    else if (type === 'intro'){
      url = INTRO_ROUTE
    }
    else {
      if (selection === 'logout'){
        return this.initiateFunction(selection)
      }
      url = `${replace(DASHBOARD_KEY_ROUTE, ':key', selection)}`
    }
    history.push(url)
    this.setState({
      type,
    })
  }

  openURL = (url) => {
    const { history } = this.props
    history.push(url)
  }

  initiateFunction = (functionName) => {
    const { applicationModel } = this.props
    switch (functionName) {
      case 'logout':
        applicationModel.logout(() => this.openURL('/login-with-email'))
        break
      default:
    }
  }

  fetchBillingList = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchBillingList(cb)
  }

  fetchSubscribers = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchSubscribers(cb, startDate, endDate)
  }

  getAdminsGeneralReportData = (cb, startDate, endDate, hours) => {
    const { adminModel } = this.props
    adminModel.getAdminsGeneralReportData(cb, startDate, endDate, hours)
  }

  createResumeOrder = (templateId, cb = () => { }) => {
    const { profileModel } = this.props
    const callback = (response) => {
      this.openBuilderOrPreview('builder', get(response, 'data'))
      if (isFunction(cb)){
        cb(response)
      }
    }
    profileModel.createResumeOrder(templateId, callback)
  }

  updateResumeOrder = (payload, cb = _ => _) => {
    const { profileModel } = this.props
    profileModel.updateResumeOrder(payload, cb)
  }

  deleteResume = (resumeId, callback) => {
    const { profileModel } = this.props
    profileModel.deleteResume(resumeId, callback)
  }

  fetchOrdersReport = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchOrdersReport(cb, startDate, endDate)
  }

  fetchResumesReport = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchResumesReport(cb, startDate, endDate)
  }

  searchResumes = (userName = '', resumeName = '', cb = _ => _) => {
    const { adminModel } = this.props
    adminModel.searchResumes(userName, resumeName, cb)
  }

  sendPromoEmails = (payload, cb = _ => _) => {
    const { adminModel } = this.props
    adminModel.sendPromoEmails(payload, cb)
  }

  fetchSubscriptionReport = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchSubscriptionReport(cb, startDate, endDate)
  }

  fetchUsers = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchUsers(cb, startDate, endDate)
  }

  fetchVisitors = (cb, startDate, endDate) => {
    const { adminModel } = this.props
    adminModel.fetchVisitors(cb, startDate, endDate)
  }

  fetchDetails = (refAPI, refKeyVal, cb) => {
    const { adminModel } = this.props
    adminModel.fetchDetails(refAPI, refKeyVal, cb)
  }

  fetchDashboard = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchDashboard(cb)
  }

  fetchPersonalInfo = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchPersonalInfo(cb)
  }

  createSubscriptionOrder = (payload, cb) => {
    const { profileModel } = this.props
    if (get(SUBSCRIPTIONPLANS.FREE, 'id') === get(payload, 'planId')){
      this.createFreeSubscriptionOrder(payload, cb)
    }
    else {
      profileModel.createSubscriptionOrder(payload, cb)
    }
  }

  createFreeSubscriptionOrder = async (payload, cb) => {
    const { profileModel } = this.props
    const callback = (response) => {
      const planId = get(response, 'planId')
      const orderId = get(response, 'orderId')
      profileModel.createSubscriptionOrder({ planId, orderId }, cb)
    }
    await profileModel.createSubscriptionOrder(payload, callback)
  }

  launchSubscriptionCart = (planId, cb) => {
    const { history } = this.props
    history.push('/dashboard/create')
    this.setState({
      introPlanId: planId,
    })
  }

  savePersonalInfo = (payload, cb) => {
    const { profileModel } = this.props
    profileModel.savePersonalInfo(payload, cb)
  }

  initiateResumeCreation = (payload, cb) => {
    const { profileModel } = this.props
    const callback = (response) => {
      if (isFunction(cb)){
        cb(response)
      }
      const userDetails = profileModel.getUserData()
      if (get(userDetails, 'subValid')){
        this.createResumeOrder(get(TEMPLATE.VENUS, 'templateId'))
      }
    }
    profileModel.savePersonalInfo(payload, callback)
  }

  initiateTransaction = (payload, cb) => {
    const { profileModel } = this.props
    const callback = (response) => {
      cb(response)
    }
    profileModel.initiateTransaction(payload, callback)
  }

  changePassword = (payload, cb) => {
    const { profileModel } = this.props
    profileModel.changePassword(payload, cb)
  }

  addNotifications = (notification) => {
    const { notifications } = this.state
    notifications.push(notification)
    this.setState({
      notifications,
    })
  }

  removeNotifications = (notification) => {
    const { notifications } = this.state
    remove(notifications, notification)
    this.setState({
      notifications,
    })
  }

  savePDF = (pdf, cb = _ => _) => {
    const { profileModel } = this.props
    const { notifications } = this.state
    const callback = (response) => {
      cb()
      this.setState({ generatingPdf: false })
      const url = get(response, 'data')
      if (url) {
        notifications.push({ show: 'my-resumes', resumeURL: url })
        this.setState({
          notifications,
        })
      }
    }
    this.setState({ generatingPdf: true })
    profileModel.savePdfFile(pdf, callback)
    this.openURL('/dashboard/myresume')
  }

  saveResumeImage = (image, cb) => {
    const { profileModel } = this.props
    profileModel.saveResumeImage(image, cb)
  }

  fetchResumeList = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchUserResumes(cb)
  }

  downloadResumeById = (id, cb) => {
    const { profileModel } = this.props
    profileModel.downloadResumeById(id, cb)
  }

  openBuilderOrPreview = (type, id) => {
    if (id){
      const { profileModel } = this.props
      const callback = (token) => {
        this.updateSelectedKey('', type)
      }
      profileModel.setResumeId(id, callback)
    }
    else {
      this.updateSelectedKey('', type)
    }
  }

  fetchResumeJson = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchResumeJson(cb)
  }

  fetchResumeDetails = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchResumeDetails(cb)
  }

  fetchResumeImage = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchResumeImage(cb)
  }

  fetchResumeImages = (cb) => {
    const { profileModel } = this.props
    profileModel.fetchResumeImages(cb)
  }

  saveResumeJson = (resume, cb) => {
    const { profileModel } = this.props
    profileModel.saveBuilderResume(resume, cb)
  }

  renderDashboard(type, userDetails, selectedKey) {
    const { applicationModel } = this.props
    const { notifications, introPlanId, generatingPdf } = this.state
    const roles = get(userDetails, 'roles')
    let isAdmin = false
    let isSupport = false
    if (hasRole(roles, 'ADMIN')) {
      isAdmin = true
    }
    else if (hasRole(roles, 'SUPPORT')){
      isSupport = true
    }
    const selPlanId = get(applicationModel.getRedirect(), 'planId') || introPlanId
    const selTemplateId = get(applicationModel.getRedirect(), 'templateId')
    return (
      <Fragment>
        { isAdmin && (
          <AdminDashboard
            changePassword={this.changePassword}
            createSubscriptionOrder={this.createSubscriptionOrder}
            downloadResumeById={this.downloadResumeById}
            fetchBillingList={this.fetchBillingList}
            fetchDetails={this.fetchDetails}
            fetchOrdersReport={this.fetchOrdersReport}
            fetchPersonalInfo={this.fetchPersonalInfo}
            fetchResumesReport={this.fetchResumesReport}
            fetchSubscribers={this.fetchSubscribers}
            fetchSubscribersReport
            fetchSubscriptionReport={this.fetchSubscriptionReport}
            fetchUsers={this.fetchUsers}
            fetchVisitors={this.fetchVisitors}
            getAdminsGeneralReportData={this.getAdminsGeneralReportData}
            getResumeList={this.fetchResumeList}
            openBuilderOrPreview={this.openBuilderOrPreview}
            page={selectedKey}
            searchResumes={this.searchResumes}
            sendPromoEmails={this.sendPromoEmails}
            type={type}
            updateSelectedKey={this.updateSelectedKey}
            userDetails={userDetails} />
        )}
        { isSupport && (
          <SupportDashboard
            changePassword={this.changePassword}
            createSubscriptionOrder={this.createSubscriptionOrder}
            downloadResumeById={this.downloadResumeById}
            fetchBillingList={this.fetchBillingList}
            fetchPersonalInfo={this.fetchPersonalInfo}
            fetchSubscribers={this.fetchSubscribers}
            fetchUsers={this.fetchUsers}
            fetchVisitors={this.fetchVisitors}
            getResumeList={this.fetchResumeList}
            page={selectedKey}
            savePersonalInfo={this.savePersonalInfo}
            type={type}
            updateSelectedKey={this.updateSelectedKey}
            userDetails={userDetails} />
        )}
        { !isAdmin && !isSupport && (
          <UserDashboard
            addNotifications={this.addNotifications}
            changePassword={this.changePassword}
            createResumeOrder={this.createResumeOrder}
            createSubscriptionOrder={this.createSubscriptionOrder}
            deleteResume={this.deleteResume}
            downloadResumeById={this.downloadResumeById}
            fetchBillingList={this.fetchBillingList}
            fetchPersonalInfo={this.fetchPersonalInfo}
            fetchSubscribers={this.fetchSubscribers}
            fetchUsers={this.fetchUsers}
            fetchVisitors={this.fetchVisitors}
            generatingPdf={generatingPdf}
            getResumeList={this.fetchResumeList}
            initiateFunction={this.initiateFunction}
            initiateTransaction={this.initiateTransaction}
            notifications={notifications}
            openBuilderOrPreview={this.openBuilderOrPreview}
            openURL={this.openURL}
            page={selectedKey}
            removeNotifications={this.removeNotifications}
            savePersonalInfo={this.savePersonalInfo}
            saveResumeImage={this.saveResumeImage}
            selectedPlanId={selPlanId}
            selectedTemplateId={selTemplateId}
            type={type}
            updateSelectedKey={this.updateSelectedKey}
            userDetails={userDetails} />
        )}
      </Fragment>
    )
  }

  render() {
    const { applicationModel, profileModel, match: { params } } = this.props
    const { type, isLoading } = this.state
    const userDetails = profileModel.getUserData()
    const personalInfo = profileModel.getPersonalInfo()
    const selectedKey = get(params, 'key', '')
    const selPlanId = get(applicationModel.getRedirect(), 'planId')
    return (
      <Suspense fallback={<Loader body={true} />}>
        {isLoading && <Loader body={true} />}
        {!isLoading && (
        <Fragment>
          {(type === 'dashboard' || type === 'builder')
            && (
            <div className={classnames(styles.page_wrapper)}>
              { type === 'dashboard' && this.renderDashboard(type, userDetails, selectedKey)}
              { type === 'builder' && (
              <Builder
                fetchResumeJson={this.fetchResumeJson}
                initiateFunction={this.initiateFunction}
                openBuilderOrPreview={this.openBuilderOrPreview}
                openURL={this.openURL}
                selectedKey={selectedKey} updateSelectedKey={this.updateSelectedKey}
                userDetails={userDetails} />
              ) }
            </div>
            )}
            { type === 'preview' && (
              <Preview
                fetchResumeDetails={this.fetchResumeDetails} fetchResumeImage={this.fetchResumeImage}
                fetchResumeImages={this.fetchResumeImages}
                fetchResumeJson={this.fetchResumeJson}
                initiateFunction={this.initiateFunction}
                openBuilderOrPreview={this.openBuilderOrPreview}
                openURL={this.openURL}
                savePDF={this.savePDF} saveResumeImage={this.saveResumeImage}
                saveResumeJson={this.saveResumeJson}
                updateResumeOrder={this.updateResumeOrder}
                userDetails={userDetails} />
            )}
            { type === 'intro' && (
              <IntroContent
                createFreeSubscriptionOrder={this.createSubscriptionOrder}
                fetchPersonalInfo={this.fetchPersonalInfo} initiateResumeCreation={this.initiateResumeCreation}
                launchSubscriptionCart={this.launchSubscriptionCart}
                personalInfo={personalInfo} savePersonalInfo={this.savePersonalInfo}
                selectedPlanId={selPlanId} validSub={get(userDetails, 'subValid')} />
            )}
        </Fragment>
        )}
      </Suspense>
    )
  }
}
export default withRouter(UserDashboardContainer)
