import { get } from 'lodash'

import { api } from './api-helper'
import { ErrorHandlingService } from '.'
import { getSearchMappedUrl, GET_UNIVERSITIES_URI, GET_COMPANIES_URI, GET_CITIES_URI, GET_TRAININGS_URI, GET_STATES_URI,
  GET_ACHIEVEMENTS_URI, GET_HOBBIES_URI, GET_EMPLOYMENT_RESPONSIBILITIES_URI, GET_EDUCATION_URI, GET_OBJECTIVES_URI,
  SEVERITY, GET_SUMMARIES_URI, GET_SKILLS_URI, GET_LANGUAGES_URI, GET_PROJECT_RESPONSIBILITIES_URI, GET_ROLES_URI,
  GET_DOMAIN_URI, GET_CERTIFICATIONS_URI, GET_INSTITUTIONS_URI, getMappedUrl } from '../constants'

export async function getUniversities(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_UNIVERSITIES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function getCompanies(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_COMPANIES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function getCities(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_CITIES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function getStates(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_STATES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchSkills(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_SKILLS_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchLanguages(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_LANGUAGES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function getAchievements(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_ACHIEVEMENTS_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchHobbies(key = '', type = 'IMAGE') {
  let response
  try {
    const url = getMappedUrl(GET_HOBBIES_URI, { key, type })
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchRoles(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_ROLES_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchDomains(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_DOMAIN_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchQualifications(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_EDUCATION_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchTrainings(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_TRAININGS_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchCertifications(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_CERTIFICATIONS_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchInstitutions(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_INSTITUTIONS_URI, searchkey)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchEmploymentResponsibilities(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_EMPLOYMENT_RESPONSIBILITIES_URI, searchkey)
    response = await api(url, { method: 'POST' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchProjectResponsibilities(searchkey = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_PROJECT_RESPONSIBILITIES_URI, searchkey)
    response = await api(url, { method: 'POST' })
  }
  catch (err) {
    console.error(get(err, 'body.details'))
  }
  return response
}

export async function fetchObjectives(criteria, key = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_OBJECTIVES_URI, key)
    response = await api(url, { method: 'POST', body: criteria })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function fetchSummaries(criteria, key = '') {
  let response
  try {
    const url = getSearchMappedUrl(GET_SUMMARIES_URI, key)
    response = await api(url, { method: 'POST', body: criteria })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}
