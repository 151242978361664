import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FOOTER } from '../../constants'
import styles from './styles.scss'

export default class Footer extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className={classnames(styles.footer)}>
        <div className={classnames(styles.float_right)}>
          <ul>
            <li> <div> <a href="https://www.resumaker.co.in/terms-conditions" target="_blank">
              Terms &amp; Conditions </a> </div>  </li>
            <li> <div> <a href="https://www.resumaker.co.in/privacy-policy" target="_blank">
              Privacy Policy </a> </div>  </li>
            <li> <div> <a href="https://www.resumaker.co.in/contact" target="_blank">
              Contact Us </a> </div>  </li>
            <li>{FOOTER.COPY_RIGH} {FOOTER.RIGHTS}</li>
          </ul>
        </div>
      </div>
    )
  }
}
