import { map } from 'lodash'
import { AppConstants } from '..'

export * from './utilities'
export * from './common'
export * from './oauth2'

export function getQueryStringComplete(options) {
  let queryString = ''
  if (options) {
    map(options, (value, prop) => {
      if (value && Array.isArray(value)) {
        value.forEach(v => {
          queryString += `&${prop}=${v}`
        })
      }
      else if (value){
        queryString += `&${prop}=${value}`
      }
    })
    if (queryString && queryString.length > 0){
      queryString = `?${queryString.substr(1)}`
    }
  }
  return queryString
}

export function getMappedUrl(urlTemplate = '', queryParams = '') {
  const mappedUrl = urlTemplate
  return `${AppConstants.dataUrl}/${mappedUrl}${getQueryStringComplete(queryParams)}`
}

export function getTemplateMappedUrl(urlTemplate = '', templateVars = [], queryParams = '') {
  let mappedUrl = urlTemplate
  templateVars.forEach((templateVar, index) => {
    mappedUrl = mappedUrl.replace(`$${index + 1}`, templateVar)
  })
  return getMappedUrl(mappedUrl, queryParams)
}

export function getSearchMappedUrl(urlTemplate = '', searchKey = '') {
  const searchObject = { key: searchKey }
  return getMappedUrl(urlTemplate, searchObject)
}

export function getFrontendMappedUrl(urlTemplate = '') {
  const mappedUrl = urlTemplate
  return `${AppConstants.frontEndUrl}/${mappedUrl}`
}
