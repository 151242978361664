import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import MainHeader from '../MainHeader'

class IntroHeader extends Component {
  render() {
    // const logoImg = '../../../assets/images/logo.svg'
    return (
      <div>
        {/* <a href="/">
          <img alt={'Resumaker'} src={logoImg} />
        </a> */}
        <MainHeader />

      </div>
    )
  }
}

export default withRouter(IntroHeader)
