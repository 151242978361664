const dataUrl = process.env.DATA_URL
const paymentUrl = process.env.PAYMENT_URL
const frontEndUrl = 'https://www.resumaker.co.in'
const resourcesUrl = 'https://d2vtatw9bzj7wp.cloudfront.net'
const helpUrl = 'https://help.resumaker.co.in'
const publicHost = process.env.PUBLIC_HOST || 'http://localhost:3002'

export const AppConstants = {
  dataUrl,
  resourcesUrl,
  paymentUrl,
  frontEndUrl,
  helpUrl,
  publicHost,
}
