import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import classnames from 'classnames'

import { getLabelStyle, getContainerStyle, getInputContainerStyle } from './Helpers'

import styles from './style.scss'

export default class PasswordComponent extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    formField: PropTypes.object,
    input: PropTypes.object,
    showLabel: PropTypes.bool,
  }

  static defaultProps = {
    formField: undefined,
    input: {},
    showLabel: true,
  }

  constructor(props) {
    super(props)
    const { input } = props
    this.state = {
      value: input.value,
    }
  }

  onChange = e => {
    const { input } = this.props
    if (input.onChange) {
      input.onChange(e)
    }
    this.setState({
      value: e.target.value,
    })
  }

  onBlur = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onBlur) {
      input.onBlur({ value: e.target.value, ...extraParameters })
    }
  }

  onKeyDown = e => {
    const { input, field: { extraParameters } } = this.props
    if (input.onKeyDown) {
      input.onKeyDown({ e, ...extraParameters })
    }
  }

  getLabel = () => {
    const { formField, field } = this.props
    if (formField) {
      return formField.get('label')
    }
    return field.label
  }

  getTitle = () => {
    const { field } = this.props
    return (field && field.title) || this.getLabel()
  }

  render() {
    const props = this.props
    const { field, formField, showLabel } = props
    const { value } = this.state
    const {
      placeholder, editable, disabled,
    } = field
    const finalLabel = this.getLabel()
    let { input } = props

    input = omit(input, ['name'])
    input.value = value
    const inputContainerClassName = classnames(getInputContainerStyle(field), 'textBox', 'password-input-container')
    const inputClass = classnames(styles.input, styles['input-type-password'])
    const defaultClass = !(this.props.formField && this.props.formField.$value && this.props.formField.$value.length) ?
      styles['password-placeholder'] : ''
    const containerClass = !(this.props.formField && this.props.formField.$value &&
      this.props.formField.$value.length) ?
      getContainerStyle(field) : ''
    return (
      <div className={classnames(styles['container-style'], defaultClass, containerClass)}>
        {showLabel && (
        <div
          className={classnames(getLabelStyle(field), 'label')}>
          {finalLabel}
        </div>
        )}
        {editable === false ?
          <div className={styles['non-editable-value']}>{input.value}</div> : (
            <div className={inputContainerClassName}>
              {!formField ? (
                <input
                  {...input}
                  className={inputClass}
                  disabled={disabled}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onKeyDown={this.onKeyDown}
                  placeholder={placeholder}
                  spellCheck={false}
                  type={'password'}
              />
              ) : (
                <input
                {...formField.bind()} // eslint-disable-line
                  className={inputClass}
                  disabled={disabled}
                  placeholder={placeholder}
                  spellCheck={false}
                  type={'password'}
              />
              )}
            </div>
          )}
      </div>
    )
  }
}
