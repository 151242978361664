// import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import classnames from 'classnames'
// import { withRouter } from 'react-router-dom'

// import { IntroContent } from '../../components'
// // import { IntroHeader } from '../../components'
// import styles from './style.scss'

// class Intro extends Component {
//   static propTypes = {
//     history: PropTypes.object.isRequired,
//     location: PropTypes.object.isRequired,
//     match: PropTypes.object.isRequired,
//   }

//   constructor(props) {
//     super(props)
//     this.url = window.location.href
//   }

//   render() {
//     return (
//       <div className={classnames(styles.main)}>
//         {/* <IntroHeader /> */}
//         {/* <IntroContent {...this.props} /> */}
//         {/* <IntroFooter /> */}
//       </div>
//     )
//   }
// }

// export default withRouter(Intro)
