import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'
import { isEqual, get } from 'lodash'

import { Icon, IconType } from '..'
import styles from './style.scss'

export default class ButtonDropdown extends Component {
  static propTypes = {
    arrowStyle: PropTypes.string,
    btnClass: PropTypes.string,
    btnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.instanceOf(Element)]),
    btnType: PropTypes.string,
    containerClass: PropTypes.string,
    dropDownView: PropTypes.bool,
    isActive: PropTypes.bool,
    isDropdownOpen: PropTypes.func,
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    listClass: PropTypes.string,
    position: PropTypes.string,
    showIcon: PropTypes.bool,
    styleClass: PropTypes.string,
  }

  static defaultProps = {
    arrowStyle: '',
    btnClass: '',
    btnLabel: '',
    btnType: 'circle',
    containerClass: '',
    dropDownView: false,
    isActive: false,
    items: '',
    listClass: '',
    position: 'bottom',
    showIcon: false,
    styleClass: '',
    isDropdownOpen: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      isDropdownOpen: false,
    }
  }

  btnAction = () => {
    const { isDropdownOpen } = this.state
    if (!isDropdownOpen) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }))
    this.props.isDropdownOpen(!isDropdownOpen)
  }

  handleOutsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }
    if (this.state.isDropdownOpen){
      this.btnAction()
    }
  }

  renderButton = label => {
    const { btnClass, btnType, dropDownView, showIcon, styleClass, isActive, arrowStyle } = this.props
    const { isDropdownOpen } = this.state
    const buttonType = isEqual(btnType, 'sqaure') ? styles['button-square'] : styles['button-circle']
    const btnStyle = dropDownView ? styles.dropdown : buttonType
    const { CHEVRONUP, CHEVRONDOWN } = IconType.DIRECTION
    const activeIconClass = (isDropdownOpen && isActive) ? styles['icon-orange'] : ''
    return (
      <div className={classnames(btnStyle, btnClass, styleClass, 'btnDropdown')} onClick={this.btnAction}>
        <span className={classnames(styles.label, activeIconClass)}>{label}</span>
        {showIcon && !isDropdownOpen && (
        <span className={classnames(styles.arrow_icon, arrowStyle)}>
          <Icon names={[CHEVRONDOWN]} styles={'icon25 darkGrayAlt'} />
        </span>
        )}
        {showIcon && isDropdownOpen && (
          <span className={classnames(styles.arrow_icon, arrowStyle)}>
            <Icon names={[CHEVRONUP]} styles={'icon25 darkGrayAlt'} />
          </span>
        )}
      </div>
    )
  }

  renderDropdownContent = () => {
    const { items, listClass, position } = this.props
    return (
      <div className={classnames(styles['dropdown-content'], listClass, styles[position])}>
        {
          items.map(item => (
            <Fragment key={get(item.props, 'children')}>
              <div className={styles.item} onClick={this.btnAction}>{item}</div>
            </Fragment>
          ))
        }
      </div>
    )
  }

  render() {
    const { btnLabel, containerClass } = this.props
    const { isDropdownOpen } = this.state
    return (
      <div
        ref={node => {
          this.node = node
        }}
        className={classnames(styles['dropdown-container'], containerClass)}>
        {this.renderButton(btnLabel)}
        {isDropdownOpen && this.renderDropdownContent()}
      </div>
    )
  }
}
