import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.scss'

export default class Loader extends Component {
  static propTypes = {
    body: PropTypes.bool,
  }

  static defaultProps = {
    body: true,
  }

  render() {
    const { body } = this.props
    return (
      <div className={classnames(styles.loader_wrap, { [styles.align_body]: body })}>
        <div className={classnames(styles['lds-spinner'])}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}
