// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".selected___1cD6X{background:#abab92}.select_button___prl3t{margin:4px 8px;padding:.25rem .5rem;border:1px solid #211f1f;color:#666;border-radius:60px;display:inline-block;vertical-align:middle;cursor:pointer;box-shadow:0 2px 1px 0 rgba(16,15,15,0.84),0 3px 1px -2px rgba(16,15,15,0.84),0 2px 1px 0 rgba(16,15,15,0.84)}.container___1GjeT{padding:10px 10px}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/resumaker-web-app/user-web-app/src/components/common/MultiSelection/style.scss"],"names":[],"mappings":"AAAA,kBACE,kBAAmB,CACpB,uBAGC,cAAe,CACf,oBAAqB,CACrB,wBAAyB,CACzB,UAAW,CACX,kBAAmB,CACnB,oBAAqB,CACrB,qBAAsB,CACtB,cAAe,CACf,6GAAyH,CAC1H,mBAGC,iBAAkB","file":"style.scss","sourcesContent":[".selected {\r\n  background: #abab92;\r\n}\r\n\r\n.select_button {\r\n  margin: 4px 8px;\r\n  padding: .25rem .5rem;\r\n  border: 1px solid #211f1f;\r\n  color: #666;\r\n  border-radius: 60px;\r\n  display: inline-block;\r\n  vertical-align: middle;\r\n  cursor: pointer;\r\n  box-shadow: 0 2px 1px 0 rgba(16, 15, 15, 0.84), 0 3px 1px -2px rgba(16, 15, 15, 0.84), 0 2px 1px 0 rgba(16, 15, 15, 0.84);\r\n}\r\n\r\n.container {\r\n  padding: 10px 10px;\r\n}"]}]);
// Exports
exports.locals = {
	"selected": "selected___1cD6X",
	"select_button": "select_button___prl3t",
	"selectButton": "select_button___prl3t",
	"container": "container___1GjeT"
};
module.exports = exports;
