import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SEORoutes, SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE, FREE_TEMPLATE_ROUTE } from '.'
import { Home, LoginContainer, UserDashboardContainer } from '../../containers'
import { PageNotFound } from '../../components'

export const SLASH_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const SIGNUP_ROUTE = '/signup'
export const SUBMIT_EMAIL_ROUTE = '/submit-email'
export const LOGIN_WITH_EMAIL_ROUTE = '/login-with-email'
export const ACTIVATE = '/activate'
export const HOME_ROUTE = '/home'
export const FAQ_ROUTE = '/faqs'
export const TERMS_ROUTE = '/terms-conditions'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'
export const PAYMENT_REFUND_ROUTE = '/payment-refunds'
export const GET_EXPERT = '/hire-expert'
export const ABOUT = '/about'
export const PRICING = '/pricing'
export const SUBSCRIPTION_ROUTE = '/subscribe'
export const UNSUBSCRIPTION_ROUTE = '/unsubscribe'
export const CONTACT_US_ROUTE = '/contact'
export const BLOG_KEY_ROUTE = '/blog/:key'
export const INTERVIEW_KEY_ROUTE = '/interview/:key'
export const CAREER_KEY_ROUTE = '/career/:key'
export const BLOG_ROUTE = '/blog'
export const INTERVIEW_ROUTE = '/interview'
export const CAREER_ROUTE = '/career'

export const MainRoutes = () => (
  <Switch>
    <Route
      exact path={SLASH_ROUTE}
      render={props => <UserDashboardContainer {...props} type={'dashboard'} />} />
    <Route exact path={HOME_ROUTE} render={props => <Home {...props} />} />
    <Route
      exact path={[LOGIN_ROUTE, SIGNUP_ROUTE, LOGIN_WITH_EMAIL_ROUTE, SUBMIT_EMAIL_ROUTE, ACTIVATE]}
      render={props => <LoginContainer {...props} />} />
    {/* <Route
      exact path={SUBMIT_OTP_ROUTE}
      render={props => <LoginContainer {...props} selectedKey={'submit-otp'} />} />
    <Route
      exact path={GENERATE_PASSWORD_ROUTE}
      render={props => <LoginContainer {...props} selectedKey={'generate-password'} />} />
    <Route
      exact path={RESETPASS_ROUTE}
      render={props => <LoginContainer {...props} selectedKey={'reset-password'} />} /> */}
    <Route exact path={TERMS_ROUTE} render={props => <Home {...props} selectedKey={'terms-conditions'} />} />
    <Route exact path={PRIVACY_POLICY_ROUTE} render={props => <Home {...props} selectedKey={'privacy-policy'} />} />
    <Route
      exact path={PAYMENT_REFUND_ROUTE}
      render={props => <Home {...props} selectedKey={'payment-refund-policy'} />} />
    <Route exact path={PRICING} render={props => <Home {...props} selectedKey={'pricing'} />} />
    <Route exact path={GET_EXPERT} render={props => <Home {...props} selectedKey={'hire-expert'} />} />
    <Route exact path={ABOUT} render={props => <Home {...props} selectedKey={'about'} />} />

    <Route exact path={FAQ_ROUTE} render={props => <Home {...props} selectedKey={'faq'} />} />
    <Route exact path={SUBSCRIPTION_ROUTE} render={props => <Home {...props} selectedKey={'subscribe'} />} />
    <Route exact path={UNSUBSCRIPTION_ROUTE} render={props => <Home {...props} selectedKey={'unsubscribe'} />} />
    <Route exact path={CONTACT_US_ROUTE} render={props => <Home {...props} selectedKey={'contact-us'} />} />
    <Route exact path={[BLOG_KEY_ROUTE, BLOG_ROUTE]} render={props => <Home {...props} selectedKey={'blog'} />} />
    <Route
      exact path={[INTERVIEW_KEY_ROUTE, INTERVIEW_ROUTE]}
      render={props => <Home {...props} selectedKey={'interview'} />} />
    <Route exact path={[CAREER_KEY_ROUTE, CAREER_ROUTE]} render={props => <Home {...props} selectedKey={'career'} />} />
    <Route component={SEORoutes} exact path={[SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE, FREE_TEMPLATE_ROUTE]} />
    <Route component={PageNotFound} path="*" />
  </Switch>
)
