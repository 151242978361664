import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

import styles from './style.scss'

export default class Panel extends Component {
  render() {
    const { leftNodeBody, rightNodeBody, style, leftNodeFooter } = this.props
    const panelAlignLeft = leftNodeBody ? styles['panel-align-left'] : ''
    return (
      <div className={classnames(style.panelContainer, panelAlignLeft, styles['panel-container'])}>
        {leftNodeBody && (
        <div className={classnames(style.leftNode, styles['panel-left'], 'leftPanel')}>
          <div className={styles['left-panel-indent']}>
            {leftNodeBody}
          </div>
          {leftNodeFooter && <div className={styles['left-panel-footer']}>{leftNodeFooter}</div>}
        </div>
        )}
        <div className={classnames(style.rightNode, styles['panel-right'], 'rightPanel')}>
          {rightNodeBody}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  leftNodeBody: PropTypes.node,
  leftNodeFooter: PropTypes.node,
  rightNodeBody: PropTypes.node,
  style: PropTypes.object,
}
Panel.defaultProps = {
  leftNodeBody: '',
  leftNodeFooter: '',
  rightNodeBody: '',
  style: { panelContainer: '', leftNode: '', rightNode: '' },
}
