import React from 'react'
import { PropTypes } from 'mobx-react'
import classnames from 'classnames'
import SocialLogin from 'react-social-login'

import styles from './style.scss'

class SocialLoginButton extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    triggerLogin: PropTypes.function,
  }
  static defaultProps = {
    children: '',
    triggerLogin: _ => _,
  }

  render() {
    return (
      <div className={classnames(styles.socialLogin)} onClick={this.props.triggerLogin} {...this.props}>
        { this.props.children }
      </div>
    )
  }
}

export default SocialLogin(SocialLoginButton)
