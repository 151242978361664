import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { reject } from 'lodash'

import styles from './style.scss'

export const DUMMY_OPTION = '$$DUMMY$$'

export default class menuRenderer extends Component {
  render() {
    const {
      valueKey, instancePrefix, valueArray, optionClassName, focusedOption,
      optionComponent, optionRenderer, options, onFocus, onSelect, onOptionRef, footer,
      appendToBody } = this.props
    const Option = optionComponent
    const filteredOptions = footer ? reject(options, option => option[valueKey] === DUMMY_OPTION) : options
    const classMenu = appendToBody ? styles['menu-renderer-container'] : ''
    return (
      <div
        className={classnames(classMenu)}>
        {filteredOptions.map((option, index) => {
          const isFocused = option === focusedOption
          const isSelected = valueArray && valueArray.indexOf(option) > -1
          const optionClass = classnames(optionClassName, {
            'Select-option': true,
            'is-selected': isSelected,
            'is-focused': isFocused,
            'is-disabled': option.disabled,
          }, option.externalClass)
          return (
            <Option
              key={`option-${index}-${option[valueKey]}`} // eslint-disable-line
              ref={ref => {
                onOptionRef(ref, isFocused)
              }}
              className={optionClass}
              instancePrefix={instancePrefix}
              isDisabled={option.disabled}
              isFocused={isFocused}
              isSelected={isSelected}
              onFocus={onFocus}
              onSelect={onSelect}
              option={option}
              optionIndex={index}
            >
              {optionRenderer(option, index)}
            </Option>
          )
        })}
        {footer}
      </div>
    )
  }
}

/*eslint-disable */
menuRenderer.propTypes = {
  elementRef: PropTypes.object,
  focusedOption: PropTypes.object,
  footer: PropTypes.node,
  instancePrefix: PropTypes.string,
  onFocus: PropTypes.func,
  onOptionRef: PropTypes.func,
  onSelect: PropTypes.func,
  optionClassName: PropTypes.string,
  optionComponent: PropTypes.func,
  optionRenderer: PropTypes.func,
  options: PropTypes.array,
  valueArray: PropTypes.array,
  valueKey: PropTypes.string,
  appendToBody: PropTypes.bool,
}
