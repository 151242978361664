/* eslint-disable react/no-unused-state */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get, set, isEqual } from 'lodash'

import { SUBSCRIPTIONPLANS } from '../../constants'
import StepsToStart from './StepsToStart'
// import Experience from './Experience'
import GetStart from './GetStart'
// import Pricing from './GetStartedfile/PriceList'
import Pricing from './Pricing'
import Contact from './Contact'
// import Qualifications from './Qualifications'
// import CurrentRole from './CurrentRole'
// import Domain from './Domain'
// import Coupon from './Coupon'
import st from './styles.scss'
import Footer from './Footer'

export default class IntroContent extends Component {
  static propTypes = {
    createFreeSubscriptionOrder: PropTypes.func.isRequired,
    fetchPersonalInfo: PropTypes.func.isRequired,
    initiateResumeCreation: PropTypes.func.isRequired,
    launchSubscriptionCart: PropTypes.func.isRequired,
    personalInfo: PropTypes.object.isRequired,
    savePersonalInfo: PropTypes.func.isRequired,
    selectedPlanId: PropTypes.number,
    validSub: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.url = window.location.href
    this.state = {
      stepIndex: 1,
      personalInfo: props.personalInfo,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (!isEqual(nextProps.personalInfo, prevState.personalInfo)) {
      return { personalInfo: nextProps.personalInfo }
    }
    return null
  }

  componentDidMount(){
    const { fetchPersonalInfo } = this.props
    fetchPersonalInfo()
  }

  gotoNext = () => {
    const { stepIndex } = this.state
    this.setState({
      stepIndex: stepIndex + 1,
    })
  }

  gotoBack = () => {
    const { stepIndex } = this.state
    this.setState({
      stepIndex: stepIndex - 1,
    })
  }

  updateField = (key, value, contentKey) => {
    const { personalInfo } = this.state
    if (contentKey) {
      set(personalInfo, `${key}`, get(value, contentKey, ''))
    }
    else {
      set(personalInfo, `${key}`, value)
    }
    this.setState({
      personalInfo,
    })
  }

  updatePersonalInfo = (personalInfo, save = false) => {
    const { stepIndex } = this.state
    this.setState({
      personalInfo,
      stepIndex: this.shouldDisplayPricing() ? stepIndex + 1 : stepIndex + 2,
    })
    if (save){
      this.savePersonalInfo(personalInfo)
    }
  }

  shouldDisplayPricing = () => {
    const { selectedPlanId, validSub } = this.props
    if (selectedPlanId || validSub){
      return false
    }
    return true
  }

  savePersonalInfo = (personalInfo, callback) => {
    const { savePersonalInfo } = this.props
    savePersonalInfo(personalInfo, callback)
  }

  initiateResumeCreation = () => {
    const { initiateResumeCreation } = this.props
    const { personalInfo } = this.state
    set(personalInfo, 'showIntro', false)
    initiateResumeCreation(personalInfo)
  }

  subscribe = (planId) => {
    const { createFreeSubscriptionOrder, launchSubscriptionCart, savePersonalInfo } = this.props
    const { stepIndex, personalInfo } = this.state
    const cb = (response) => this.setState({
      stepIndex: stepIndex + 1,
      orderDetails: response,
    })
    if (get(SUBSCRIPTIONPLANS.FREE, 'id') === planId){
      createFreeSubscriptionOrder({ planId }, cb)
    }
    else {
      set(personalInfo, 'showIntro', false)
      savePersonalInfo(personalInfo)
      launchSubscriptionCart(planId)
    }
  }

  updateOrder = (couponCode, callback = _ => _) => {
    const { createSubscriptionOrder } = this.props
    const { cartDetails, stepIndex } = this.state
    const planId = get(cartDetails, 'planId')
    const orderId = get(cartDetails, 'orderId')
    const cb = (response) => {
      this.setState({
        stepIndex: stepIndex + 1,
      })
    }
    createSubscriptionOrder({ planId, couponCode, orderId }, cb)
  }

  render() {
    const { personalInfo, stepIndex } = this.state
    const stepComponents = [
      { class: 'stepstostart',
        component: <StepsToStart onNext={this.gotoNext} /> },
      // { class: 'education',
      //   component: <Qualifications
      //     onBack={this.gotoBack} onNext={this.gotoNext}
      //     qualification={get(personalInfo, 'eduLevel')}
      //     updateQualification={(val) => this.updateField('eduLevel', val)} /> },
      // { class: 'experience',
      //   component: <Experience
      //     experience={get(personalInfo, 'expLevel')} onBack={this.gotoBack}
      //     onNext={this.gotoNext}
      //     updateExperience={(val) => this.updateField('expLevel', val)} /> },
      // { class: 'currentRole',
      //   component: <CurrentRole
      //     onBack={this.gotoBack} onNext={this.gotoNext}
      //     personalInfo={personalInfo}
      //     updateField={this.updateField} /> },
      // { class: 'domain',
      //   component: <Domain
      //     domain={get(personalInfo, 'domain')} onBack={this.gotoBack}
      //     onNext={this.gotoNext}
      //     updateDomain={(val) => this.updateField('domain', val)} /> },
      { class: 'contact',
        component: <Contact
          onBack={this.gotoBack}
          personalInfo={personalInfo}
          updatePersonalInfo={this.updatePersonalInfo} /> },
      { class: 'pricing',
        component: <Pricing
          onBack={this.gotoBack} onNext={this.gotoNext}
          subscribe={this.subscribe} /> },
      { class: 'start',
        component: <GetStart
          initiateResumeCreation={this.initiateResumeCreation}
          onBack={() => this.setState({ stepIndex: this.shouldDisplayPricing() ? stepIndex - 1 : stepIndex - 2 })} /> },
    ]
    return (
      <div className={cs(st.intro_layout, st[`${get(stepComponents[stepIndex - 1], 'class')}`])}>
        <div className={cs(st.intro_logo)}>
          <img alt="logo" src="https://www.resumaker.co.in/assets/images/logo.svg" />
        </div>
        <div className={cs(st.inner_layout)}>
          { get(stepComponents[stepIndex - 1], 'component') }
        </div>
        <Footer />
      </div>
    )
  }
}
