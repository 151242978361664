import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import classnames from 'classnames'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { registerForGoogleAnalytics, AppConstants } from '../../constants'
// import { MainHeader, MainFooter } from '../../components'
// import styles from './style.scss'

@inject('mainModel', 'applicationModel')
@observer
class Home extends Component {
  static propTypes = {
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: '',
  }

  componentDidMount(){
    const { location, history } = this.props
    const pathname = get(location, 'pathname')
    registerForGoogleAnalytics(history)
    window.location.assign(`${AppConstants.publicHost}${pathname}`)
  }

  render() {
    const { location } = this.props
    const pathname = get(location, 'pathname')
    window.location.assign(`${AppConstants.publicHost}${pathname}`)
    return (
      <div>
        {/* <MainHeader />
        <MainFooter /> */}
      </div>
    )
  }
}

export default withRouter(Home)
