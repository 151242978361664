import { observable, action, toJS } from 'mobx'
import { isFunction, get, set, includes, isEmpty } from 'lodash'
import { createSubscriptionOrder, fetchBillingDetail, fetchBillingList, fetchDashboard, fetchResumeImages,
  // getProfile,
  saveProfile, saveResumeImage, createResume, fetchPersonalInfo, initiateTransaction, resetPassword,
  initiatePaymentGatewayCall, savePersonalInfo, getResetPasswordToken, changePassword, savePdfFile, fetchResumeDetails,
  fetchResumeJson, fetchResumeImage, createResumeOrder, deleteResume, addTokenURL, updateSubscriptionOrder,
  fetchUserResumes, downloadResumeById, updateResumeOrder, fetchResumeByAdmin, fetchImageByAdmin } from '../services'
import { SUBSCRIPTIONPLANS } from '../constants'

export default class ProfileModel {
  @observable personalInfo
  @observable userData

  constructor() {
    this.initialize()
  }

  @action
  initialize() {
    this.personalInfo = {}
    this.resumeId = 0
    this.tokenMap = {}
  }

  getPersonalInfo() {
    return toJS(this.personalInfo)
  }

  @action
  setPersonalInfo(personalInfo){
    this.personalInfo = personalInfo
  }

  @action
  setUserData(userData){
    this.userData = userData
  }

  @action
  setStoreValue(key, value) {
    this[key] = value
  }

  getUserData(){
    return toJS(this.userData)
  }

  updateValidSubscription(subscriptionDetails){
    if (get(SUBSCRIPTIONPLANS.FREE, 'id') === get(subscriptionDetails, 'planId')){
      const userData = this.getUserData()
      set(userData, 'subValid', true)
      set(userData, 'subPlanId', get(subscriptionDetails, 'planId'))
      this.setUserData(userData)
    } else if (get(subscriptionDetails, 'noPaymentNeeded', false) === true){
      const userData = this.getUserData()
      set(userData, 'subValid', true)
      set(userData, 'subPlanId', get(subscriptionDetails, 'planId'))
      this.setUserData(userData)
    }
  }

  async setResumeId(resumeId, cb = () => {}){
    if (this.resumeId !== resumeId) {
      const response = await addTokenURL(resumeId, cb)
      if (response){
        const token = get(response, 'data')
        this.setToken(resumeId, token)
      }
    }
    if (isFunction(cb)){
      cb(get(this.tokenMap, resumeId))
    }
  }

  setToken(resumeId, token){
    set(this.tokenMap, resumeId, token)
    this.resumeId = resumeId
  }

  getToken() {
    return get(this.tokenMap, this.resumeId)
  }

  async saveProfile(profile, cb = () => { }) {
    let body = profile
    if (body){
      set(body, 'id', this.profileId)
    }
    else {
      body = this.profile
    }
    const response = await saveProfile(body, this.profileId)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async savePdfFile(payload, cb = () => { }) {
    const response = await savePdfFile(payload, this.resumeId)
    if (isFunction(cb)){
      cb(response)
    }
    return response
  }

  async saveBuilderResume(resume, cb = () => {}){
    const response = await createResume(resume, this.resumeId)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async saveResumeImage(image, cb = () => {}){
    const response = await saveResumeImage(image, this.resumeId)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async downloadResumeById(id, cb = () => { }) {
    if (id){
      set('id', id)
    }
    const response = await downloadResumeById(id)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async createSubscriptionOrder(subscriptionDetails, cb = () => { }) {
    let response
    if (get(subscriptionDetails, 'orderId')){
      response = await updateSubscriptionOrder(subscriptionDetails)
    }
    else {
      response = await createSubscriptionOrder(subscriptionDetails)
    }
    if (response && isFunction(cb)){
      this.updateValidSubscription(subscriptionDetails)
      cb(response)
    }
    return response
  }

  async createResume(templateDetails, cb = () => { }) {
    const response = await createResume(templateDetails)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async deleteResume(resumeId, cb = () => { }) {
    const response = await deleteResume(resumeId)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchUserResumes(cb = () => {}){
    const response = await fetchUserResumes()
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchResumeJson(cb = () => {}){
    const userData = this.getUserData()
    let response = null
    if (includes(get(userData, 'roles'), 'ADMIN')){
      response = await fetchResumeByAdmin(this.resumeId)
    }
    else {
      response = await fetchResumeJson(this.resumeId)
    }
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchResumeDetails(cb = () => {}){
    const userData = this.getUserData()
    if (includes(get(userData, 'roles'), 'ADMIN')){
      return
    }
    const response = await fetchResumeDetails(this.resumeId)
    if (response && isFunction(cb)){
      cb(response)
    }
  }

  async fetchResumeImage(cb = () => {}){
    const userData = this.getUserData()
    let response = null
    if (includes(get(userData, 'roles'), 'ADMIN')){
      response = await fetchImageByAdmin(this.resumeId)
    } else {
      response = await fetchResumeImage(this.resumeId)
    }
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchResumeImages(cb = () => {}){
    const response = await fetchResumeImages()
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchDashboard(identifer = '', cb = () => { }) {
    const response = await fetchDashboard(identifer)
    this.setUserData(response)
    if (identifer !== ''){
      this.setToken(get(response, 'tokenId', 0), identifer)
    }
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchBillingDetail(id, cb = () => { }) {
    if (id){
      set('id', id)
    }
    const response = await fetchBillingDetail(id)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchBillingList(cb = () => { }) {
    const response = await fetchBillingList()
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async fetchPersonalInfo(cb = () => { }) {
    if (isEmpty(this.getPersonalInfo())){
      const response = await fetchPersonalInfo()
      if (response && isFunction(cb)){
        this.setPersonalInfo(response)
        cb(response)
      }
    }
    else if (isFunction(cb)){
      cb(this.getPersonalInfo())
    }
    return this.getPersonalInfo()
  }

  async savePersonalInfo(payload, cb = () => { }) {
    const response = await savePersonalInfo(payload)
    if (response && isFunction(cb)){
      const userData = this.getUserData()
      set(userData, 'basicDetails', get(payload, 'basicDetails'))
      this.setUserData(userData)
      this.setPersonalInfo(payload)
      cb(response)
    }
    return response
  }

  async changePassword(payload, cb = () => { }) {
    const response = await changePassword(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async resetPassword(payload, cb = () => { }) {
    const response = await resetPassword(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async getResetPasswordToken(payload, cb = () => { }) {
    set(payload, 'userName', '')
    const response = await getResetPasswordToken(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async createResumeOrder(templateId, cb = () => { }) {
    const response = await createResumeOrder(templateId)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async updateResumeOrder(payload, cb = () => { }) {
    const response = await updateResumeOrder(this.resumeId, payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async initiateTransaction(payload, cb = () => { }) {
    const response = await initiateTransaction(payload)
    // this.initiatePaymentGatewayCall(response)
    if (response && isFunction(cb)){
      this.updateValidSubscription(response)
      cb(response)
    }
    return response
  }

  async initiatePaymentGatewayCall(payload) {
    const response = await initiatePaymentGatewayCall(payload)
    console.log('PaymentGateway resonse', response)
  }
}
