/* eslint-disable no-nested-ternary */
import cs from 'classnames'
import { includes, forOwn, isEmpty, filter, get, set, isArray, trim, findIndex, forEach, orderBy } from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga'
import { DATE_FORMAT, TEMPLATE, SOCIAL_NETWORK } from '.'
import { AppConstants } from './application-constants'

export const YEARS = function (year) {
  const startYear = (typeof (year) === 'undefined') ? 1980 : year
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = startYear; i <= currentYear - 15; i++){
    years.push({ label: `${i}`, value: `${i}` })
  }
  return years
}

export const fieldsPresent = (details, fields) => {
  let canSave = true
  canSave = fields.every((key) => {
    if (isEmpty(get(details, `${key}.content`, ''))){
      return false
    }
    return true
  })
  return canSave
}

export const fetchClassSet = (st, constMeta, additionalMeta, classRef) => {
  const d = []
  if (constMeta && constMeta.length > 0){
    constMeta.map((m, i) => d.push(st[m]))
  }
  if (additionalMeta && additionalMeta.length > 0 && isArray(additionalMeta)){
    additionalMeta.map((m, i) => d.push(st[m]))
  }
  d.push(classRef)
  return cs(d)
}

export const validateEmail = email => {
  // eslint-disable-next-line
  const emailRegex = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return emailRegex.test(email)
}

export const validatePassword = passwrd => {
  // eslint-disable-next-line
	const passwrdRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm
  return passwrdRegex.test(passwrd)
}

export const validateOtp = otp => {
  // eslint-disable-next-line
	  const passwrdOtp = /^[0-9]+$/
  return passwrdOtp.test(otp)
}

export const validateNumber = number => {
  const numberRegix = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  return numberRegix.test(number)
}

export const modifyMeta = (meta, prefixStyle, updatedStyle) => {
  const newMeta = meta
  let found = false
  for (let i = 0; i < newMeta.length; i++){
    if (newMeta[i].startsWith(prefixStyle)){
      found = true
      newMeta[i] = updatedStyle
    }
  }
  if (!found){
    newMeta.push(updatedStyle)
  }
  return newMeta
}

export const hasRole = (roles, role) => {
  const present = includes(roles, role)
  return present
}

export const prepareFormData = (payload, file) => {
  const formData = new FormData()
  forOwn(payload, (value, key) => formData.append(key, value))
  // payload.map((key, value) => formData.append(key, value))
  return formData
}

export const dataURIToFile = (src, fileName, mimeType, resolve) => (fetch(src)
  .then((res) => res.arrayBuffer())
  .then((buf) => {
    const file = new File([buf], fileName, { type: mimeType })
    const fileURL = URL.createObjectURL(file)
    resolve(fileURL)
  })
)

const getText = (htmlText) => {
  const tmp = document.createElement('div')
  tmp.innerHTML = htmlText
  return tmp.textContent || tmp.innerText
}

export const empty = (text) => {
  if (isEmpty(text)){
    return true
  }
  const pureText = getText(text)
  return isEmpty(trim(pureText))
}

export const existsNEmptyContent = (item, key) => {
  if (item){
    return empty(get(item, key))
  }
  return false
}

export const cleanEmptyContents = (list) => {
  const filteredList = filter(list, (o) => !isEmpty(get(o, 'content'))
    && !isEmpty(trim(getText(get(o, 'content')))))
  return filteredList
}

export const dateMatch = (moment1, date2) => {
  if (moment1 === null && date2 === null) return true
  if (moment1 === null || date2 === null) return false
  return moment1.isSame(date2)
}

export const getValidDate = (date, format) => {
  if (moment(date, format).isValid()){
    return moment(date, format).toDate()
  }
  return null
}

export const getDateParams = (strDate, format = DATE_FORMAT) => {
  if (moment(strDate, format).isValid()){
    const date = moment(strDate, format)
    return { month: date.format('MMM'), year: date.format('YYYY') }
  }
  return null
}

export const getValidMoment = (strDate, format) => {
  if (moment(strDate, format).isValid()){
    return moment(strDate, format)
  }
  return null
}

export function getImageUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/${filePath}`
}

export function getBlogImageUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/media/images/blog/${filePath}`
}

export function getBlogVideoUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/media/videos/blog/${filePath}`
}

export function getInteviewImageUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/media/images/interview/${filePath}`
}

export function getInterviewVideoUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/media/videos/interview/${filePath}`
}
export function getDocUrl(filePath = '') {
  return `${AppConstants.resourcesUrl}/${filePath}`
}

export function concatValues(val1, val2, separator) {
  let res = ''
  if (val1 && val2) {
    res = `${val1}${separator}${val2}`
  }
  else {
    if (val1){
      res = val1
    }
    if (val2) {
      res = val2
    }
  }
  return (
    res
  )
}

export function getMonth(date) {
  return get(getDateParams(date), 'month', '')
}

export function getYear(date) {
  return get(getDateParams(date), 'year', '')
}

export const handleReturn = (e, callback) => {
  if (e.keyCode === 13){
    callback()
  }
}

export const handleStartEnd = (start, end, current, till = 'Present', present = 'Present') => {
  let date = ''
  if (current && current === 'true'){
    if (start){
      date = `${start} - ${till}`
    }
    else {
      date = present
    }
  }
  else if (start && end){
    date = `${start} - ${end}`
  }
  else {
    date = !start ? end : start
  }
  return date
}

export const findJsonPath = (path, obj) => {
  if (path.startsWith('sections')){
    const sections = get(obj, 'sections')
    const regex = /^sections\[(.*)\](.*)$/g || ['', '', '']
    const arr = regex.exec(path)
    const index = findIndex(sections, { name: arr[1] })
    const calcPath = `sections[${index}]${arr[2]}`
    return calcPath
  }
  return path
}

const prepareSectionPositionsIfNotPresent = (sections, templateId) => {
  const defaultPos = get(TEMPLATE, `${templateId}.positions`)
  forEach(sections, section => {
    if (!get(section, `position.${templateId}`)){
      set(section, `position.${templateId}`, get(defaultPos, section.name))
    }
  })
}

export const arrangeSectionsForPreview = (sections, templateId, getElement) => {
  const top = []
  const left = []
  const right = []
  prepareSectionPositionsIfNotPresent(sections, templateId)
  const orderedSections = orderBy(sections, `position.${templateId}.ypos`, 'asc')
  forEach(orderedSections, section => {
    const xpos = get(section, `position.${templateId}.xpos`)
    const pos = xpos === 0 ? 'left' : xpos === 1 ? 'right' : 'top'
    const ele = getElement(section, pos)
    if (ele){
      if (pos === 'left'){
        left.push(ele)
      }
      else if (pos === 'right'){
        right.push(ele)
      }
    }
  })
  return { top, left, right }
}

export const sendGoogleAnalytics = (pathname) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(pathname)
  }
}

export const registerForGoogleAnalytics = (history) => {
  if (process.env.NODE_ENV === 'production') {
    history.listen((location) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  }
}

export const prepareSocialLinkUrls = function (socialLinks) {
  forEach(socialLinks, d => {
    const socialName = get(d, 'networkName')
    let profileName = get(d, 'networkUrl')
    profileName = profileName.replace(get(SOCIAL_NETWORK, `${socialName}.prefix`), '')
    set(d, 'networkUrl', profileName)
  })
  return socialLinks
}
