// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ":root{--rounded25: 25px;--space05: 5px;--space12: 12px;--panelBoxShadow: 0 5px 10px 0 rgba(0, 0, 0, .1)}.menu-renderer-container___1Zbqj{position:fixed;overflow:auto;max-height:200px;border-radius:var(--rounded25);text-align:center;padding:var(--space05) 0;left:-10px;min-width:202px;border:0;box-shadow:var(--panelBoxShadow);margin-top:var(--space12);z-index:9999}.menu-renderer-container___1Zbqj.hideOptions___1I0Hn{display:none}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/resumaker-web-app/user-web-app/src/components/common/form/OptionsSelector/style.scss"],"names":[],"mappings":"AAAA,MACE,iBAAY,CACZ,cAAU,CACV,eAAU,CACV,gDAAiB,CAClB,iCAGC,cAAe,CACf,aAAc,CACd,gBAAiB,CACjB,8BAA+B,CAC/B,iBAAkB,CAClB,wBAAyB,CACzB,UAAW,CACX,eAAgB,CAChB,QAAS,CACT,gCAAiC,CACjC,yBAA0B,CAC1B,YAAa,CACd,qDAGC,YAAa","file":"style.scss","sourcesContent":[":root {\r\n  --rounded25: 25px;\r\n  --space05: 5px;\r\n  --space12: 12px;\r\n  --panelBoxShadow: 0 5px 10px 0 rgba(0, 0, 0, .1);\r\n}\r\n\r\n.menu-renderer-container {\r\n  position: fixed;\r\n  overflow: auto;\r\n  max-height: 200px;\r\n  border-radius: var(--rounded25);\r\n  text-align: center;\r\n  padding: var(--space05) 0;\r\n  left: -10px;\r\n  min-width: 202px;\r\n  border: 0;\r\n  box-shadow: var(--panelBoxShadow);\r\n  margin-top: var(--space12);\r\n  z-index: 9999;\r\n}\r\n\r\n.menu-renderer-container.hideOptions {\r\n  display: none;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"menu-renderer-container": "menu-renderer-container___1Zbqj",
	"menuRendererContainer": "menu-renderer-container___1Zbqj",
	"hideOptions": "hideOptions___1I0Hn"
};
module.exports = exports;
