import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { includes, set } from 'lodash'
import Selection from './Selection'
import styles from './style.scss'

export default class MultiSelection extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    onSelection: PropTypes.func.isRequired,
    onDeSelection: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    const options = []
    props.options.forEach(opt => {
      if (includes(props.selected, opt.value)){
        set(opt, 'selected', true)
      }
      options.push(opt)
    })
    this.state = {
      options,
    }
  }

  change = (select, value) => {
    const { options } = this.state
    const { onSelection, onDeSelection } = this.props
    options.forEach(opt => {
      if (opt.value === value){
        set(opt, 'selected', select)
      }
    })
    if (select) {
      onSelection(value)
    }
    else {
      onDeSelection(value)
    }
    this.setState({
      options,
    })
  }

  render() {
    const { options } = this.state
    return (
      <div className={classnames(styles.container)}>
        {options && options.length > 0 && options.map((option, index) => (
          <Selection
            label={option.label}
            onChange={this.change}
            selected={option.selected}
            value={option.value} />
        ))}
      </div>
    )
  }
}
