import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { get, set } from 'lodash'

import { ErrorHandlingService } from '../../services'
import { MESSAGES, SEVERITY } from '../../constants'
import Login from './Login'
import Signup from './Signup'
import SubmitEmail from './SubmitEmail'
import LoginwithEmail from './LoginwithEmail'
import SubmitOtp from './SubmitOtp'
import GeneratePassword from './GeneratePassword'

const queryString = require('query-string')

class LoginPage extends Component {
  static propTypes = {
    getResetPasswordToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    launchDashboard: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    openURL: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    selectedKey: PropTypes.string,
    updatePassword: PropTypes.func.isRequired,
    updateSelectedKey: PropTypes.func.isRequired,
    validateOTP: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedKey: 'login',
  }

  constructor(props){
    super(props)
    const params = queryString.parse(props.location.search)
    this.state = {
      userName: '',
      password: '',
      showModal: 'false',
      email: '',
      token: get(params, 'token', ''),
      changePassword: false,
      selectedKey: props.selectedKey,
    }
  }

  getRenderComponent() {
    const { openURL, selectedKey } = this.props
    const { token, email } = this.state
    switch (selectedKey) {
      case 'login':
        return <Login {...this.props} openURL={openURL} />
      case 'signup':
        return <Signup openURL={openURL} registerUser={this.registerUser} />
      case 'submit-email':
        return <SubmitEmail getPasswordResetToken={this.getPasswordResetToken} openURL={openURL} />
      case 'login-with-email':
        return <LoginwithEmail {...this.props} openURL={openURL} />
      case 'submit-otp':
        //   case 'activate':
        return <SubmitOtp openURL={openURL} submitOTP={this.validateOTP} token={token} />
      case 'generate-password':
        return <GeneratePassword email={email} openURL={openURL} token={token} updatePassword={this.updatePassword} />
      default:
        return <Login {...this.props} openURL={openURL} />
    }
  }

  registerUser = (payload) => {
    const { register } = this.props
    const cb = (response) => {
      this.setState({
        token: get(response, 'token'),
        changePassword: false,
        email: get(payload, 'email'),
      })
      this.props.updateSelectedKey('submit-otp')
    }
    register(payload, cb)
  }

  getPasswordResetToken = (payload) => {
    const { getResetPasswordToken } = this.props
    const cb = (response) => {
      this.setState({
        token: get(response, 'data'),
        changePassword: true,
        email: get(payload, 'email'),
      })
      this.props.updateSelectedKey('submit-otp')
    }
    getResetPasswordToken(payload, cb)
  }

  validateOTP = (payload) => {
    const { validateOTP } = this.props
    const { changePassword, email } = this.state
    const cb = (response) => {
      if (changePassword) {
        this.props.updateSelectedKey('generate-password')
      }
      else {
        ErrorHandlingService.showMessage({ message: MESSAGES.OTP_VALIDATED_SUCCESS, severity: SEVERITY.INFO })
        this.props.openURL('login')
      }
    }
    set(payload, 'email', email)
    validateOTP(payload, cb)
  }

  updatePassword = (payload) => {
    const { updatePassword } = this.props
    const cb = (response) => {
      this.setState({
        token: '',
        changePassword: false,
      })
      this.props.openURL('login')
    }
    updatePassword(payload, cb)
  }

  updateSelectedKey = (selectedKey) => {
    this.setState({
      selectedKey,
    })
  }

  openHome = (e) => {
    if (e) e.preventDefault()
    const { history } = this.props
    history.push('/')
  }

  updateField = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  login = () => {
    const { login } = this.props
    login(this.state)
  }

  render() {
    return (
      <Fragment>
        {this.getRenderComponent()}
      </Fragment>
    )
  }
}
export default withRouter(LoginPage)
