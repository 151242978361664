import { RouterStore } from 'mobx-react-router'

import ToastModel from './ToastModel'
import ApplicationModel from './ApplicationModel'
import UtilitiesModel from './UtilitiesModel'
import ProfileModel from './ProfileModel'
import MainModel from './MainModel'
import AdminModel from './AdminModel'

export const stores = {
  toastModel: new ToastModel(),
  applicationModel: new ApplicationModel(),
  profileModel: new ProfileModel(),
  routing: new RouterStore(),
  utilitiesModel: new UtilitiesModel(),
  mainModel: new MainModel(),
  adminModel: new AdminModel(),
}
