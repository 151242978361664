import classnames from 'classnames'

import styles from './style.scss'

export function getLabelStyle(field) {
  const { style = {} } = field
  return style.labelStyle ? `${styles.label} ${style.labelStyle}` : styles.label
}

export function getInputContainerStyle(field, noDefault = false) {
  const { style = {} } = field
  const defaultClass = noDefault ? '' : styles['input-container']
  return style.inputContainerStyle ? `${styles['input-container']} ${style.inputContainerStyle}` : defaultClass
}

export function getContainerStyle(field) {
  const { style = {} } = field
  return classnames(styles['container-style'], style.container, {
    [styles['non-editable-main-container']]: field.editable === false,
  })
}
