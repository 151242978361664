import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import styles from './style.scss'

@inject('applicationModel')
class Header extends Component {
  static propTypes = {
    sideBarMini: PropTypes.bool,
    toggleSideBar: PropTypes.func,
  }

  static defaultProps = {
    sideBarMini: false,
    toggleSideBar: _ => _,
  }

  renderLogo = () => {
    const { toggleSideBar, sideBarMini } = this.props
    // const isTabContent = isEmpty(tabBody)
    // const logoAlignLeft = alignLeft ? styles['logo-left'] : ''
    // const logoCenter = isTabContent ? styles['logo-center'] : ''
    const logoImg = '/assets/images/logo.svg'
    const logoImgSM = '/assets/images/logo-sm.png'
    const title = 'Resumaker'
    const styleClass = sideBarMini ? styles['mini-sidebar'] : styles['large-sidebar']
    return (
      <div className={classnames(styles.topbar)}>
        <nav className={classnames(styles['top-navbar'])}>
          <div className={classnames(styles['navbar-header'])}>
            <a className={classnames(styles['navbar-brand'])} href={'/'}>
              <span className={classnames(styles.logo, styleClass)}>
                <img alt={title} className={styles['logo-lg']} src={logoImg} />
                <img alt={title} className={styles['logo-sm']} src={logoImgSM} />
              </span>
            </a>
          </div>
          <div className={classnames(styles['navbar-collapse'])}>
            <div className={classnames(styles['navbar-nav mr-auto'])}>
              <div className={classnames(styles['nav-item'])} onClick={toggleSideBar} />
            </div>
          </div>
        </nav>
      </div>
    )
  }

  renderLogoutButton = () => {
    const { applicationModel, mainRoom, deliberation } = this.props
    const user = applicationModel.getBasicUserInfo()
    const firstName = get(user, 'firstName')
    const lastName =
      get(user, 'lastName') === undefined
        ? ''
        : get(user, 'lastName').charAt(0)
    const userName = `${firstName} ${lastName}`
    const helpOption =
      mainRoom || deliberation ? (
        <div onClick={() => this.displayHelpText(true)}>Help</div>
      ) : (
        ''
      )
    const dropdownItems = [
      helpOption,
      <div onClick={this.logout}>Sign Out</div>,
    ]
    return (
      <div className={classnames(styles['user-dropdown'], 'userDropdown')}>
        <ButtonDropdown
          btnLabel={userName}
          dropDownView={true}
          items={dropdownItems}
          listClass={styles.dropdownList}
          showIcon={true}
        />
      </div>
    )
  }

  render = () => (
    <Fragment>
      {this.renderLogo()}
    </Fragment>
  )
}

Header.propTypes = {
  alignLeft: PropTypes.bool.isRequired,
  applicationModel: PropTypes.object.isRequired,
  history: PropTypes.object,
  node: PropTypes.node,
  onSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  showLogout: PropTypes.bool,
  tabBody: PropTypes.array.isRequired,
}

Header.defaultProps = {
  node: null,
  history: {},
  selectedTab: 0,
  onSelect: () => {},
  showLogout: true,
}

export default withRouter(Header)
